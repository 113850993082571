<template>
  <div class="comments-container">
    <h3>댓글</h3>
    <button @click="toggleDateSelection">{{ t('message.selectDate') }}</button>
    <div class="date-selection" v-if="showDateSelection">
      <label for="start-date">{{ t('message.startDate') }}:</label>
      <input type="date" id="start-date" v-model="startDate" />
      <label for="end-date">{{ t('message.endDate') }}:</label>
      <input type="date" id="end-date" v-model="endDate" />
      <button @click="fetchCommentsByDate">{{ t('message.fetchComments') }}</button>
    </div>

    <div class="comment-input">
      <input type="checkbox" v-model="isAnonymous">
      <div class="input-group">
        <textarea v-model="newComment" @keyup.enter="submitComment" class="comment-field" placeholder="댓글을 입력하세요..."></textarea>
        <label for="image-upload" class="image-icon">
          <i class="fas fa-image"></i>
          <input type="file" id="image-upload" @change="onFileChange" accept="image/*" hidden>
        </label>
        <button @click="submitComment" class="submit-button">➤</button>
      </div>
      <p v-if="uploadStatus">{{ uploadStatusMessage }}</p>
    </div>
    <p v-if="isLoading">로딩 중...</p>
    <p v-else-if="comments.length === 0 && !isLoading">댓글이 없습니다.</p>

    <ul class="comments-list">
      <li v-for="comment in sortedComments" :key="comment.id" class="comment">
        <div v-if="!comment.isAnonymous" class="user-info">
          <img :src="comment.userImage" class="user-image" alt="User Image">
          <div class="user-details">
            <span class="user-name">{{ comment.userName }}</span>
            <span class="comment-time">{{ formatTime(comment.createdAt) }}</span>
          </div>
        </div>
        <p class="comment-text">{{ formatText(comment.text) }}</p>
        <img v-if="comment.imageUrl" :src="comment.imageUrl" class="comment-image" alt="Comment image">
        <div class="interaction-buttons">
          <span @click="toggleReplyInput(comment.id)" class="reply-link">답글 달기</span>
          <font-awesome-icon 
            icon="thumbs-up" 
            @click="handleLike(comment)" 
            :class="{ active: comment.userLiked }"
          />
          <span class="like-count">{{ comment.likes }}</span>
          <font-awesome-icon 
            icon="thumbs-down" 
            @click="handleDislike(comment)" 
            :class="{ active: comment.userDisliked }"
          />
          <span class="dislike-count">{{ comment.dislikes }}</span>
        </div>
        <div v-if="replyingTo === comment.id" class="reply-input">
          <input type="text" v-model="replyText" placeholder="답글을 입력하세요...">
          <button @click="submitReply(comment.id)">답글 추가</button>
        </div>
        <ul v-if="comment.replies && comment.replies.length > 0">
          <li v-for="reply in comment.replies" :key="reply.id">
            <div class="reply-details">
              <img :src="reply.userImage" class="user-image" alt="Reply User Image">
              <span class="user-name">{{ reply.userName }}</span>
              <span class="comment-time">{{ formatTime(reply.createdAt) }}</span>
              <p class="comment-text">{{ formatText(reply.text) }}</p>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, watch, onMounted, computed } from 'vue';
import { db, storage } from '@/firebase';
import { collection, addDoc, query, orderBy, getDocs, serverTimestamp, doc, updateDoc, getDoc, where } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { formatDistanceToNow } from 'date-fns';
import { enUS, ko } from 'date-fns/locale';
import { getAuth } from 'firebase/auth';
import { arrayUnion, arrayRemove } from 'firebase/firestore';


export default {
  name: 'UserComments',

  props: ['uniqueId'],
  setup(props) {
    const { t } = useI18n();
    const userInfo = ref({});
    const comments = ref([]);
    const newComment = ref('');
    const selectedFile = ref(null);
    const uploadStatus = ref(false);
    const uploadStatusMessage = ref('');
    const isAnonymous = ref(false);
    const replyingTo = ref(null);
    const replyText = ref('');
    const isLoading = ref(false);
    const auth = getAuth();
    const startDate = ref(null);
    const endDate = ref(null);
    const locale = ref(navigator.language.startsWith('ko') ? ko : enUS);
    const showDateSelection = ref(false);

    onMounted(async () => {
      if (props.uniqueId.trim() !== '') {
        await fetchData(props.uniqueId);
      }
    });

    watch(() => props.uniqueId, async (newVal) => {
      if (newVal.trim() !== '') {
        await fetchData(newVal);
      }
    }, { immediate: true });

    const sortedComments = computed(() => {
      return comments.value.sort((a, b) => calculateScore(b) - calculateScore(a));
    });

    function calculateScore(comment) {
      const timeFactor = (new Date() - comment.createdAt) / (1000 * 60 * 60 * 24);
      return comment.likes - timeFactor;
    }

    function toggleDateSelection() {
      showDateSelection.value = !showDateSelection.value;
    }

    const fetchCommentsByDate = async () => {
      if (!startDate.value || !endDate.value) {
        alert('시작 날짜와 종료 날짜를 모두 입력해주세요.');
        return;
      }

      const start = new Date(startDate.value + 'T00:00:00');
      const end = new Date(endDate.value + 'T23:59:59');

      isLoading.value = true;

      try {
        const commentQuery = query(
          collection(db, "comments", props.uniqueId, "userComments"),
          where('createdAt', '>=', start),
          where('createdAt', '<=', end),
          orderBy("createdAt", "desc")
        );

        const querySnapshot = await getDocs(commentQuery);
        comments.value = await Promise.all(querySnapshot.docs.map(async docRef => {
          const data = docRef.data();
          let userImage = 'default-anonymous-image-url';
          let userName = '익명';

          if (!data.isAnonymous && data.userId) {
            const userDocRef = doc(db, "users", data.userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
              const userData = userDoc.data();
              userImage = userData.photoURL || 'default-anonymous-image-url';
              userName = userData.name || '익명';
            }
          }

          let replies = [];
          if (data.replies && data.replies.length > 0) {
            replies = await Promise.all(data.replies.map(async reply => {
              let replyUserImage = 'default-anonymous-image-url';
              let replyUserName = '익명';
              if (!reply.isAnonymous && reply.userId) {
                const replyUserDocRef = doc(db, "users", reply.userId);
                const replyUserDoc = await getDoc(replyUserDocRef);
                if (replyUserDoc.exists()) {
                  const replyUserData = replyUserDoc.data();
                  replyUserImage = replyUserData.photoURL || 'default-anonymous-image-url';
                  replyUserName = replyUserData.name || '익명';
                }
              }

              const replyCreatedAt = reply.createdAt ? new Date(reply.createdAt.seconds * 1000) : '시간 정보 없음';

              return {
                ...reply,
                userImage: replyUserImage,
                userName: replyUserName,
                createdAt: replyCreatedAt
              };
            }));
          }

          const currentUser = auth.currentUser;
          const userLikes = Array.isArray(data.userLikes) ? data.userLikes : [];
          const userDislikes = Array.isArray(data.userDislikes) ? data.userDislikes : [];
          const userLiked = currentUser && userLikes.includes(currentUser.uid);
          const userDisliked = currentUser && userDislikes.includes(currentUser.uid);

          return {
            ...data,
            id: docRef.id,
            createdAt: data.createdAt.toDate(),
            userImage: userImage,
            userName: userName,
            likes: data.likes || 0,
            dislikes: data.dislikes || 0,
            userLikes: userLikes,
            userDislikes: userDislikes,
            userLiked: userLiked,
            userDisliked: userDisliked,
            replies: replies
          };
        }));
      } catch (error) {
        console.error('Error fetching comments:', error);
        alert('댓글을 불러오는 데 실패했습니다.');
      } finally {
        isLoading.value = false;
      }
    };

    async function fetchData(uniqueId) {
      await fetchUserDetails(uniqueId);
      await fetchComments(uniqueId);
    }

    async function handleLike(comment) {
      if (!auth.currentUser) {
        alert("로그인이 필요합니다.");
        return;
      }
      const currentUser = auth.currentUser;
      const userId = currentUser.uid;
      const commentRef = doc(db, "comments", props.uniqueId, "userComments", comment.id);

      const userLikes = comment.userLikes || [];
      const userDislikes = comment.userDislikes || [];

      if (comment.userLiked) {
        comment.userLikes = userLikes.filter(uid => uid !== userId);
        comment.likes--;
        comment.userLiked = false;
      } else {
        if (comment.userDisliked) {
          comment.userDislikes = userDislikes.filter(uid => uid !== userId);
          comment.dislikes--;
          comment.userDisliked = false;
        }
        comment.userLikes.push(userId);
        comment.likes++;
        comment.userLiked = true;
      }

      try {
        await updateDoc(commentRef, {
          userLikes: comment.userLikes,
          userDislikes: comment.userDislikes,
          likes: comment.likes,
          dislikes: comment.dislikes
        });
      } catch (error) {
        console.error('Failed to update likes:', error);
      }
    }

    async function handleDislike(comment) {
      if (!auth.currentUser) {
        alert("로그인이 필요합니다.");
        return;
      }
      const currentUser = auth.currentUser;
      const userId = currentUser.uid;
      const commentRef = doc(db, "comments", props.uniqueId, "userComments", comment.id);

      const userLikes = comment.userLikes || [];
      const userDislikes = comment.userDislikes || [];

      if (comment.userDisliked) {
        comment.userDislikes = userDislikes.filter(uid => uid !== userId);
        comment.dislikes--;
        comment.userDisliked = false;
      } else {
        if (comment.userLiked) {
          comment.userLikes = userLikes.filter(uid => uid !== userId);
          comment.likes--;
          comment.userLiked = false;
        }
        comment.userDislikes.push(userId);
        comment.dislikes++;
        comment.userDisliked = true;
      }

      try {
        await updateDoc(commentRef, {
          userLikes: comment.userLikes,
          userDislikes: comment.userDislikes,
          likes: comment.likes,
          dislikes: comment.dislikes
        });
      } catch (error) {
        console.error('Failed to update dislikes:', error);
      }
    }

    async function fetchComments(uniqueId) {
      isLoading.value = true;
        console.log(`Fetching comments from ${startDate.value} to ${endDate.value} for uniqueId: ${props.uniqueId}`);
      try {
        const commentQuery = query(collection(db, "comments", uniqueId, "userComments"), orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(commentQuery);
        comments.value = await Promise.all(querySnapshot.docs.map(doc => mapCommentData(doc)));
      } catch (error) {
        console.error('Error fetching comments:', error);
      } finally {
        isLoading.value = false;
      }
    }

    async function fetchUserDetails(userId) {
      isLoading.value = true;
      try {
        const userSnap = await getDoc(doc(db, "users", userId));
        userInfo.value = userSnap.exists() ? userSnap.data() : {};
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        isLoading.value = false;
      }
    }

    async function mapCommentData(doc) {
      const comment = doc.data();
      const userData = comment.isAnonymous ? {} : await fetchUserData(comment.userId);

      const replies = await Promise.all((comment.replies || []).map(async (reply) => {
        if (reply.isAnonymous || !reply.userId) {
          return {
            ...reply,
            userImage: '',
            userName: '익명',
            createdAt: convertToDate(reply.createdAt)
          };
        }
        const replyUserData = await fetchUserData(reply.userId);
        return {
          ...reply,
          userImage: replyUserData.photoURL || '',
          userName: replyUserData.name || '익명',
          createdAt: convertToDate(reply.createdAt)
        };
      }));

      const currentUser = auth.currentUser;
      const userLikes = comment.userLikes || [];
      const userDislikes = comment.userDislikes || [];
      const userLiked = currentUser && userLikes.includes(currentUser.uid);
      const userDisliked = currentUser && userDislikes.includes(currentUser.uid);

      return {
        ...comment,
        id: doc.id,
        userImage: userData.photoURL || 'default-anonymous-image-url',
        userName: userData.name || '?',
        createdAt: convertToDate(comment.createdAt),
        userLiked: userLiked,
        userDisliked: userDisliked,
        likes: comment.likes || 0,
        dislikes: comment.dislikes || 0,
        userLikes: userLikes,
        userDislikes: userDislikes,
        replies
      };
    }

    function convertToDate(timestamp) {
      if (timestamp && timestamp.toDate) {
        return timestamp.toDate();
      } else if (timestamp instanceof Date) {
        return timestamp;
      } else {
        return new Date();
      }
    }

    function formatTime(timestamp) {
      if (!timestamp || !(timestamp instanceof Date)) {
        return '시간 정보 없음';
      }
      try {
        return formatDistanceToNow(timestamp, { addSuffix: true, locale: locale.value });
      } catch (error) {
        console.error('날짜 형식 오류:', error);
        return '날짜 형식 오류';
      }
    }

    async function fetchUserData(userId) {
      if (!userId) return {};
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      return userSnap.exists() ? userSnap.data() : {};
    }

    function onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        selectedFile.value = file;
      } else {
        alert('이미지 파일만 업로드 가능합니다.');
        selectedFile.value = null;
      }
    }

    async function uploadImage(file) {
      const storagePath = `comments/${props.uniqueId}/${Date.now()}-${file.name}`;
      try {
        const uploadTaskSnapshot = await uploadBytes(storageRef(storage, storagePath), file);
        return await getDownloadURL(uploadTaskSnapshot.ref);
      } catch (error) {
        console.error('Error uploading image:', error);
        return '';
      }
    }

    async function submitComment() {
      if (newComment.value.trim().length > 500) {
        uploadStatusMessage.value = '댓글은 500자 이하이어야 합니다.';
        uploadStatus.value = true;
        return;
      }

      if (newComment.value.trim() || selectedFile.value) {
        const imageUrl = selectedFile.value ? await uploadImage(selectedFile.value) : '';
        const currentUser = auth.currentUser;
        if (!currentUser) {
          uploadStatusMessage.value = '로그인해주세요.';
          uploadStatus.value = true;
          return;
        }
        await addDoc(collection(db, "comments", props.uniqueId, "userComments"), {
          text: newComment.value,
          userId: isAnonymous.value ? null : currentUser.uid,
          createdAt: serverTimestamp(),
          imageUrl,
          isAnonymous: isAnonymous.value
        });
        newComment.value = '';
        selectedFile.value = null;
        await fetchComments(props.uniqueId);
      } else {
        uploadStatusMessage.value = '댓글을 입력해주세요.';
        uploadStatus.value = true;
      }
    }

    async function submitReply(commentId) {
      if (replyText.value.trim()) {
        const currentUser = auth.currentUser;
        await updateDoc(doc(db, "comments", props.uniqueId, "userComments", commentId), {
          replies: arrayUnion({
            id: Date.now().toString(),
            text: replyText.value,
            createdAt: new Date(),
            userId: isAnonymous.value ? null : currentUser.uid,
            isAnonymous: isAnonymous.value,
            userImage: currentUser.photoURL || 'default-anonymous-image-url',
            userName: currentUser.displayName || '익명'
          })
        });
        replyText.value = '';
        replyingTo.value = null;
        await fetchComments(props.uniqueId);
      }
    }

    function toggleReplyInput(commentId) {
      replyingTo.value = replyingTo.value === commentId ? null : commentId;
    }

    function formatText(text) {
      if (!text) return '';
      const regex = /.{1,20}(?=\s|$)|\S+?(?=\s|$)/g;
      return text.match(regex).join('\n');
    }

    return {
      newComment, userInfo, comments, submitComment, onFileChange, formatTime,
      uploadStatus, uploadStatusMessage, isAnonymous, toggleReplyInput,
      submitReply, replyText, replyingTo, isLoading, handleDislike, handleLike,
      comments, startDate, endDate, fetchCommentsByDate, showDateSelection, toggleDateSelection, t,
      sortedComments, formatText
    };
  }
};
</script>



<style scoped>
.comment-text {
 white-space: pre-wrap; /* 줄 바꿈 문자 및 공백 문자를 그대로 유지 */
}
.like-dislike-buttons FontAwesomeIcon {
  cursor: pointer;
  margin-right: 5px;
}
.like-dislike-buttons .active {
  color: #007bff; /* 좋아요는 파란색, 싫어요는 빨간색으로 설정 */
}
.comment-time {
  margin: 0;
  font-size: 0.85rem; /* 폰트 크기 조정 */
  color: #888; /* 폰트 색상 조정 */
  position: static; /* 흐름에 따라 위치 조정 */
}

.submit-button {
  display: inline-flex; /* Flexbox 모델 사용 */
  justify-content: center; /* 가로축 중앙 정렬 */
  align-items: center; /* 세로축 중앙 정렬 */
  padding: 5px 20px; /* 상하좌우 패딩 */
  font-size: 20px; /* 아이콘 크기 */
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  width: 10px;
  height: 35px;
}
.user-info {
  display: flex;
  align-items: center; /* 이미지와 이름을 세로 중앙 정렬 */
  margin-bottom: 8px; /* 이름과 댓글 텍스트 사이의 간격 조정 */
}
.user-name {
  margin-right: 10px; /* 이름과 시간 사이 간격 */
}


.input-group {
  display: flex;
  align-items: center; /* 아이템을 세로 중앙에 배치 */
}

.image-icon i {
  font-size: 30px; /* 아이콘 크기 조정 */
  color: #262626; /* 아이콘 색상 - 필요에 따라 조정 가능 */
  margin-right: 10px;
}

.image-upload-button i {
  font-size: 24px; /* 아이콘 크기 */
  color: #24710c; /* 아이콘 색상 - 인스타그램과 유사하게 */
}

.user-image {
  width: 40px; /* 이미지 크기 조정 */
  height: 40px; /* 이미지 크기 조정 */
  border-radius: 50%; /* 원형 이미지로 만들기 */
  object-fit: cover; /* 이미지 비율 유지 */
  margin-right: 5px; /* 이미지와 이름 사이의 간격 */
}

.reply-input, .replies-list {
  margin-left: 25px; /* 여백 조정 */
}

.comment-image {
  max-width: 100%;
  max-height: 250px; /* 이미지 최대 높이 조정 */
  height: auto;
  margin-top: 12px; /* 마진 조정 */
  object-fit: cover;
  border-radius: 10px; /* 이미지 둥근 모서리 조정 */
}

.comments-container {
  max-width: 100%;
  margin: auto;
  padding: 20px; /* 패딩 조정 */
  background-color: #ffffff; /* 배경색 조정 */
  box-shadow: 0 4px 8px rgba(0,0,0,0.05); /* 그림자 효과 추가 */
  border-radius: 10px; /* 둥근 모서리 조정 */
}
.reply-link {
  cursor: pointer;
  color: black; /* 원래 색상 */
  
  font-size: 0.85em; /* 기본 텍스트 크기보다 작게 */
  
}

.reply-link:hover {
  text-decoration: none; /* 호버 시 밑줄 제거 */
  color: rgba(0, 123, 255, 0.8); /* 호버 시 색상 약간 진하게 */
}
.comment-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* 마진 조정 */
}

.comment-input input[type="text"],
.comment-input input[type="file"] {
  margin: 6px 0; /* 여백 조정 */
  padding: 10px; /* 패딩 조정 */
  border: 1px solid #eee; /* 테두리 색상 조정 */
  border-radius: 6px; /* 둥근 모서리 조정 */
  font-size: 16px; /* 폰트 크기 조정 */
  background-color: #f7f7f7; /* 배경색 조정 */
}

.comment-input button {
  padding: 10px 20px; /* 패딩 조정 */
  font-size: 16px; /* 폰트 크기 조정 */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px; /* 둥근 모서리 조정 */
  cursor: pointer;
  margin-top: 6px; /* 여백 조정 */
  transition: background-color 0.3s; /* 배경색 변경 애니메이션 */
}

.comment-input button:hover {
  background-color: #0056b3; /* 호버 시 배경색 변경 */
}

.comments-list {
  list-style-type: none;
  padding: 0;
}

.comment-field {
  min-height: 38px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;  
  overflow-y: auto;  
}

.comment {
  padding: 10px; /* 패딩 조정 */
  border-bottom: 1px solid #f0f0f0; /* 테두리 색상 조정 */
}

.comment-text {
  margin: 0 0 8px 0; /* 마진 조정 */
}

.comment-time {
  margin: 0;
  font-size: 0.85rem; /* 폰트 크기 조정 */
  color: #888; /* 폰트 색상 조정 */
}

@media (min-width: 768px) {
  .comment-input {
    flex-direction: row;
  }

  .comment-input input[type="text"],
  .comment-input input[type="file"] {
    margin-right: 15px; /* 여백 조정 */
  }

  .comment-input button {
    margin-top: 0;
  }
}
</style>




