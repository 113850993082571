<template>
  <div class="post-form">
    <div v-if="renderComponent">
      <div class="post-header">
        <img :src="userProfile.photoURL || 'default-profile-photo.jpg'" alt="Profile Photo" class="profile-photo">
        <span>{{ userProfile.uniqueId || userProfile.displayName }}</span>
      </div>
      <input v-model="newPostTitle" placeholder="게시물 제목을 입력하세요" />
      <div class="content-editor">
        <div 
          contenteditable="true" 
          class="editable-content" 
          @input="updateContent" 
          ref="contentEditor"
          @paste="handlePaste"
          @mousedown="handleMouseDown"
        ></div>
      </div>
      <div class="file-upload">
        <label>Upload an image:</label>
        <input type="file" @change="handleFileUpload('postImageFile', $event)" />
      </div>
      <div class="file-upload">
        <label>Upload a thumbnail:</label>
        <input type="file" @change="handleFileUpload('thumbnailImageFile', $event)" />
      </div>
      <div v-if="thumbnailImageFile" class="thumbnail-preview">
        <img :src="thumbnailImageFile" alt="Thumbnail Preview" class="thumbnail-preview-image" />
      </div>
      <div class="file-upload">
        <label>Upload a video:</label>
        <input type="file" @change="handleVideoFileUpload($event)" />
      </div>
      <div class="link-upload">
        <label>Attach a link:</label>
        <input v-model="newPostLink" @input="fetchLinkPreview" placeholder="Paste a link..." />
      </div>
      <div v-if="linkPreview" class="link-preview">
        <a :href="newPostLink" target="_blank">
          <img :src="linkPreview.image" alt="Link Preview Image" class="link-preview-image" />
          <p>{{ linkPreview.title }}</p>
        </a>
      </div>
      <button @click="submitPost">Submit</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import { auth, db, storage } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

export default {
  name: 'PostForm',
  props: {
    boardId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      newPostTitle: '',
      postContent: '',
      newPostLink: '',
      linkPreview: null,
      postImageFile: null,
      thumbnailImageFile: null,
      videoFile: null,
      resizingImage: null,
      startX: 0,
      startWidth: 0,
      renderComponent: false,
      userProfile: {
        photoURL: '',
        displayName: '',
        uniqueId: ''
      }
    };
  },
  computed: {
    ...mapGetters(['userProfile'])
  },
  methods: {
    ...mapActions(['initializeAuthState']),
    async refreshUserProfile() {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            this.userProfile.photoURL = userData.photoURL || 'default-profile-photo.jpg';
            this.userProfile.displayName = userData.displayName || 'Anonymous';
            this.userProfile.uniqueId = userData.uniqueId || user.uid;
          } else {
            this.userProfile.photoURL = 'default-profile-photo.jpg';
            this.userProfile.displayName = 'Anonymous';
            this.userProfile.uniqueId = user.uid;
          }
        } else {
          this.userProfile.photoURL = 'default-profile-photo.jpg';
          this.userProfile.displayName = 'Anonymous';
          this.userProfile.uniqueId = '';
        }
        this.renderComponent = true;
      });
    },
    updateContent() {
      const contentEditor = this.$refs.contentEditor;
      this.postContent = contentEditor.innerHTML;
    },
    async handleFileUpload(type, event) {
      const file = event.target.files[0];
      if (file) {
        const fileUrl = await this.uploadFile(file);
        if (fileUrl) {
          const contentEditor = this.$refs.contentEditor;
          contentEditor.focus();
          if (type === 'postImageFile') {
            const imgTag = `<div><img src="${fileUrl}" class="inserted-image" style="max-width: 50%;" /></div>`;
            contentEditor.innerHTML += imgTag;
            this.updateContent();
          } else if (type === 'thumbnailImageFile') {
            this.thumbnailImageFile = fileUrl;
          }
        }
      }
    },
    handlePaste(event) {
      event.preventDefault();
      const text = (event.clipboardData || window.clipboardData).getData('text');
      document.execCommand('insertHTML', false, text);
    },
    async handleVideoFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 50 * 1024 * 1024) { // 50MB 제한
          alert("파일 크기는 50MB를 초과할 수 없습니다.");
          return;
        }
        const videoUrl = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.src = videoUrl;
        video.addEventListener('loadedmetadata', () => {
          if (video.duration > 60) { // 1분 제한
            alert("동영상 길이는 1분을 초과할 수 없습니다.");
          } else {
            this.uploadFile(file).then((fileUrl) => {
              const contentEditor = this.$refs.contentEditor;
              contentEditor.focus();
              const videoTag = `<div><video src="${fileUrl}" class="inserted-video" controls style="max-width: 100%;"></video></div>`;
              contentEditor.innerHTML += videoTag;
              this.updateContent();
            });
          }
        });
      }
    },
    async uploadFile(file) {
      if (!file) return '';
      const storageReference = storageRef(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageReference, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          (error) => {
            console.error('File upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    },
    async fetchLinkPreview() {
      if (this.newPostLink) {
        try {
          const response = await fetch(`https://api.linkpreview.net?key=YOUR_API_KEY&q=${this.newPostLink}`);
          const data = await response.json();
          this.linkPreview = data;
        } catch (error) {
          console.error('Failed to fetch link preview:', error);
        }
      } else {
        this.linkPreview = null;
      }
    },
    async submitPost() {
      if (!this.newPostTitle.trim() || !this.postContent.trim()) {
        alert("제목과 내용을 입력하세요.");
        return;
      }

      try {
        const newPost = {
          user: {
            displayName: this.userProfile.displayName,
            photoURL: this.userProfile.photoURL,
            uniqueId: this.userProfile.uniqueId
          },
          title: this.newPostTitle,
          content: this.postContent,
          thumbnailUrl: this.thumbnailImageFile,
          link: this.newPostLink,
          linkPreview: this.linkPreview,
          createdAt: new Date().toISOString(),
          views: 0,
          boardId: this.boardId
        };

        const docRef = await addDoc(collection(db, "boards", this.boardId, "posts"), newPost);

        this.$router.push({ name: 'PostView', params: { boardId: this.boardId, postId: docRef.id } });
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    },
    handleMouseDown(event) {
      const target = event.target;
      if (target.tagName === 'IMG' && target.classList.contains('inserted-image')) {
        this.resizingImage = target;
        this.startX = event.clientX;
        this.startWidth = target.offsetWidth;
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
      }
    },
    handleMouseMove(event) {
      if (this.resizingImage) {
        const newWidth = this.startWidth + (event.clientX - this.startX);
        this.resizingImage.style.width = `${newWidth}px`;
      }
    },
    handleMouseUp() {
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);
      this.resizingImage = null;
    }
  },
  created() {
    this.refreshUserProfile();
  }
};
</script>

<style scoped>
.post-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.post-header .profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.editable-content {
  min-height: 150px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

.file-upload,
.link-upload {
  margin-bottom: 10px;
}

.thumbnail-preview {
  margin-bottom: 10px;
}

.thumbnail-preview-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.link-preview {
  display: flex;
  align-items: center;
}

.link-preview-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #218838;
}
</style>


<style scoped>
.post-form {
  max-width: 600px;
  margin: 0 auto;
}

.create-post {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.content-editor {
  margin-top: 10px;
}

.editable-content {
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 200px;
  border-radius: 4px;
  margin-top: 10px;
  text-align: left; /* 이 줄을 추가하여 텍스트가 왼쪽 정렬되도록 함 */
}

.file-upload, .link-upload {
  margin-top: 10px;
}

.thumbnail-preview {
  margin-top: 10px;
}

.thumbnail-preview-image {
  max-width: 100%;
  border-radius: 4px;
}

.link-preview {
  margin-top: 10px;
}

.link-preview-image {
  max-width: 100%;
  border-radius: 4px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}
</style>




