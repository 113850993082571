<template>
  <div class="login-container">
    <div v-if="loading">{{ $t('message.loading') }}</div>
    <div v-else-if="loginSuccess && !userDataExists" class="login-form">
      <input type="text" v-model="userName" :placeholder="$t('message.userNamePlaceholder')" class="input-field" @input="handleInput" />
      <input type="text" v-model="userUniqueId" :placeholder="$t('message.uniqueIdPlaceholder')" class="input-field" @input="handleInput" />
      <p v-if="isInvalidUserName" class="invalid-warning">{{ $t('message.invalidUserName') }}</p>
      <p v-if="isInvalidUniqueId" class="invalid-warning">{{ $t('message.invalidUniqueId') }}</p>
      <p v-if="isDuplicate" class="duplicate-warning">{{ $t('message.duplicateID') }}</p>
      <button @click="saveUserData" :disabled="isButtonDisabled || inputProcessing" class="action-btn">{{ $t('message.confirm') }}</button>
    </div>
    <div v-else-if="userDataExists" class="user-data-container">
      <div class="welcome-message">{{ $t('message.welcome', { name: userName }) }}</div>
      <button @click="goToHome" class="home-btn">{{ $t('message.home') }}</button>
    </div>
    <div v-if="loginFailed" class="login-failed">{{ $t('message.loginFailed') }}</div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

export default {
  setup() {
    const router = useRouter();
    const auth = getAuth();
    const userName = ref('');
    const userUniqueId = ref('');
    const isDuplicate = ref(false);
    const isInvalidUniqueId = ref(false);
    const isInvalidUserName = ref(false);
    const userDataExists = ref(false);
    const loading = ref(true);
    const loginSuccess = ref(false);
    const loginFailed = ref(false);
    const isButtonDisabled = ref(true);
    const inputProcessing = ref(false);

    let timer = null;

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          userDataExists.value = true;
          userName.value = docSnap.data().name;
          userUniqueId.value = docSnap.data().uniqueId;
          router.push('/'); // 바로 홈으로 리디렉션
        } else {
          userDataExists.value = false;
          userName.value = user.displayName || user.email.split('@')[0];
        }
        loginSuccess.value = true;
      } else {
        loginFailed.value = true;
      }
      loading.value = false;
    });

    const handleInput = () => {
      clearTimeout(timer);
      inputProcessing.value = true;
      timer = setTimeout(() => {
        validateInput();
      }, 500);
    };

    const validateInput = async () => {
      isInvalidUserName.value = userName.value.length > 8;
      const regex = /^[A-Za-z0-9!@#$%^&*()_+=\-[\]{};':"\\|,.<>/?]+$/;
      isInvalidUniqueId.value = !regex.test(userUniqueId.value) || userUniqueId.value.length > 10;

      if (!isInvalidUserName.value && !isInvalidUniqueId.value) {
        await checkForDuplicateUniqueId();
      } else {
        isButtonDisabled.value = true;
      }
      inputProcessing.value = false;
    };

    const checkForDuplicateUniqueId = async () => {
      const userRef = collection(db, 'users');
      const q = query(userRef, where('uniqueId', '==', userUniqueId.value));
      const snapshot = await getDocs(q);
      isDuplicate.value = !snapshot.empty;
      isButtonDisabled.value = isInvalidUserName.value || isInvalidUniqueId.value || isDuplicate.value;
    };

    const saveUserData = async () => {
      if (!isButtonDisabled.value) {
        const uid = auth.currentUser.uid;
        const userDocRef = doc(db, 'users', uid);
        await setDoc(userDocRef, {
          name: userName.value,
          uniqueId: userUniqueId.value
        });
        userDataExists.value = true;
        router.push('/');
      }
    };

    const goToHome = () => {
      router.push('/');
    };

    return {
      userName,
      userUniqueId,
      isDuplicate,
      isInvalidUniqueId,
      isInvalidUserName,
      userDataExists,
      loading,
      loginSuccess,
      loginFailed,
      goToHome,
      handleInput,
      saveUserData,
      isButtonDisabled,
      inputProcessing
    };
  }
};
</script>



<style>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* 중앙 정렬을 유지합니다 */
  height: 100vh;
  padding-top: 100px; /* 상단 패딩으로 버튼을 위로 올립니다 */

}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.invalid-warning, .duplicate-warning {
  color: red;
  margin-bottom: 10px;
}

.loading, .login-failed {
  color: #555;
  font-size: 16px;
  margin-top: 20px;
}

.welcome-message {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px; /* 메시지 아래 여백을 줄입니다. */
}

.action-btn, .home-btn {
  font-size: 17px;
  padding: 10px 20px;
  border: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px; /* 버튼 사이의 간격을 조정합니다. */
  width: auto; /* 버튼 너비를 내용에 따라 조정합니다. */
  transition: background-color 0.3s ease;
}

.action-btn:hover, .home-btn:hover {
  background: #0056b3;
}

.action-btn:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.home-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-top: 10px; /* 버튼 위에 간격을 추가합니다 */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-btn:hover {
  background-color: #218838;
}

.login-form,
.user-data-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 340px;
  margin-bottom: 20px; /* 폼과 환영 메시지 사이의 여백을 줄입니다. */
}
</style>
