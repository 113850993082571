import { createRouter, createWebHistory } from 'vue-router';
import About from '@/views/About.vue';
import UserProfile from './views/UserProfile.vue';
import Community from './views/Community.vue';
import PostDetail from './components/PostDetail.vue';
import PostForm from './views/PostForm.vue';
import BoardList from './views/BoardList.vue'; // BoardList 컴포넌트를 임포트합니다.
import CreateBoard from './views/CreateBoard.vue'; // CreateBoard 컴포넌트를 임포트합니다.
import BoardDetail from './views/BoardDetail.vue'; // BoardDetail 컴포넌트를 임포트합니다.
import PostView from './views/PostView.vue'; // PostView 컴포넌트를 임포트합니다.

const routes = [
  { path: '/about', name: 'About', component: About },
  {
    path: '/community',
    name: 'Community',
    component: Community
  },
  {
    path: '/create-post',
    name: 'CreatePost',
    component: PostForm
  },
  {
    path: '/post/:postId',
    name: 'PostDetail',
    component: PostDetail,
    props: true,
    meta: {
      title: '게시물 제목 - 사이트 이름',
      description: '게시물에 대한 요약 설명',
    }
  },
  {
    path: '/user/:uniqueId',
    name: 'UserProfile',
    component: UserProfile,
    props: true
  },
  // 새로운 게시판 관련 라우트 추가
  {
    path: '/boards',
    name: 'BoardList',
    component: BoardList
  },
  {
    path: '/create-board',
    name: 'CreateBoard',
    component: CreateBoard
  },
  {
    path: '/boards/:boardId',
    name: 'BoardDetail',
    component: BoardDetail
  },
  {
    path: '/boards/:boardId/posts/:postId',
    name: 'PostView',
    component: PostView,
    props: true,
    meta: {
      title: '게시물 제목 - 사이트 이름',
      description: '게시물에 대한 요약 설명',
    }
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;





