<template>
  <div class="search-container">
    <!-- 돋보기 아이콘 -->
    <div class="icon-wrapper" @click="toggleSearchBar">
      <i class="fas fa-search search-icon"></i> <!-- Font Awesome 돋보기 아이콘 -->
    </div>

    <!-- 검색창 및 버튼 -->
    <div v-show="showSearch" class="search-bar">
      <input
        type="text"
        v-model="searchQuery"
        @keyup.enter="searchUser"
        :placeholder="$t('message.search')"
        class="search-input"
      />
      <button @click="searchUser" class="search-button">
        {{$t('message.searchButton')}}
      </button>
    </div>

    <!-- 사용자 프로필 -->
    <div v-if="userProfile" class="user-profile" @click="goToUserProfile(userProfile.uniqueId)">
      <img :src="userProfile.photoURL || defaultPicture" :alt="$t('message.profilePicture')" class="profile-picture" />
      <div class="user-info">
        <h2>{{ userProfile.name }}</h2>
        <p>{{ userProfile.uniqueId }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router'; // Vue Router의 useRouter 훅을 import합니다.
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

export default {
  setup() {
    const router = useRouter();
    const searchQuery = ref('');
    const userProfile = ref(null);
    const showSearch = ref(false); // 검색창 표시 여부
    const defaultPicture = "https://via.placeholder.com/150";

    const toggleSearchBar = () => {
      showSearch.value = !showSearch.value; // 검색창 표시 상태를 토글
    };

    const searchUser = async () => {
      if (!searchQuery.value) return;
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("uniqueId", "==", searchQuery.value.trim()));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        userProfile.value = querySnapshot.docs[0].data();
      } else {
        alert("No user found with that unique ID.");
        userProfile.value = null;
      }
    };

    const goToUserProfile = (uniqueId) => {
      router.push({ name: 'UserProfile', params: { uniqueId } });
    };

    return {
      searchQuery,
      userProfile,
      showSearch,
      defaultPicture,
      searchUser,
      goToUserProfile,
      toggleSearchBar,
    };
  },
};
</script>

<style scoped>
/* 검색 아이콘 및 컨테이너 스타일 */
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.icon-wrapper {
  cursor: pointer;
  position: absolute; /* 위치 절대 지정 */
  left: 20px; /* 왼쪽으로 이동 */
}

.search-icon {
  font-size: 30px;
  color: #555;
}

/* 검색창 및 버튼 스타일 */
.search-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
}

.search-input {
  padding: 12px 20px;
  margin-bottom: 15px;
  font-size: 18px;
  border: 2px solid #ddd;
  border-radius: 25px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}

.search-button {
  padding: 10px 25px;
  cursor: pointer;
  font-size: 18px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #3f55b5;
}

/* 사용자 프로필 스타일 */
.user-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info h2, .user-info p {
  margin: 5px 0;
  text-align: center;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .search-container {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    margin-top: 30px;
  }

  .icon-wrapper {
    left: 10px; /* 모바일에서 아이콘을 더 왼쪽으로 이동 */
  }

  .search-input {
    width: 60%;
    max-width: 250px;
    margin: 45px auto 0 auto;
    padding: 8px 16px;
    order: 1;
  }

  .search-button {
    order: 2;
    padding: 8px 20px;
    margin-top: 1px;
    margin-right: 55px;
  }

  .user-profile {
    order: 0;
    width: 100%;
    justify-content: center;
  }

  .profile-picture {
    width: 80px;
    height: 80px;
  }

  .user-info h2 {
    font-size: 20px;
  }
}
</style>


