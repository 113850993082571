<template>
  <div v-if="renderComponent && post" class="post-detail">
    <UserProfileHeader :user="post.user" :defaultProfilePhoto="defaultProfilePhoto" />
    <h2>{{ post.title }}</h2>
    <div class="post-header">
      <div class="post-info">
        <!-- 여기에 필요한 정보를 추가할 수 있습니다 -->
      </div>
    </div>
    <div class="post-actions">
  <button @click="sharePost" class="share-button">
    공유
  </button>
</div>
    <div class="post-content" v-html="renderedPostContent"></div>
    <small>{{ formatDate(post.createdAt) }}</small>
    <div class="post-views">
      <small>조회수: {{ post.views }}</small>
    </div>
    <div class="reactions">
      <button :disabled="isReacting" @click="handleReaction('like')" class="reaction-button like" :class="{ active: userReaction === 'like' }">
        ▲추천 {{ post.likes || 0 }}
      </button>
      <button :disabled="isReacting" @click="handleReaction('dislike')" class="reaction-button dislike" :class="{ active: userReaction === 'dislike' }">
        ▼비추 {{ post.dislikes || 0 }}
      </button>
    </div>
    <div class="comments-section">
  <h3>댓글 ({{ comments.length }})</h3> <!-- 댓글 갯수 표시 -->
  <div v-for="comment in comments" :key="comment.id" class="comment">
        <img :src="comment.user.photoURL || defaultProfilePhoto" alt="Profile Photo" class="profile-photo" @click="handleCommentUserClick(comment)">
        <div class="comment-content">
          <div class="comment-header">
            <span>{{ comment.user.displayName || comment.user.name }}</span>
            <small>{{ timeSince(comment.createdAt) }}</small>
          </div>
          <div v-html="renderedCommentContent(comment.content)"></div>
          <img v-if="comment.imageUrl" :src="comment.imageUrl" alt="Comment Image" class="comment-image">
          <div class="comment-reactions">
            <button :disabled="comment.isReacting" @click="handleCommentReaction(comment.id, 'like')" class="reaction-button like" :class="{ active: comment.userReaction === 'like' }">
              <font-awesome-icon icon="thumbs-up" /> {{ comment.likes || 0 }}
            </button>
            <button :disabled="comment.isReacting" @click="handleCommentReaction(comment.id, 'dislike')" class="reaction-button dislike" :class="{ active: comment.userReaction === 'dislike' }">
              <font-awesome-icon icon="thumbs-down" /> {{ comment.dislikes || 0 }}
            </button>
          </div>
        </div>
      </div>
      <div class="new-comment">
        <h4>새 댓글</h4>
        <div class="comment-header">
          <img :src="userProfile.photoURL || defaultProfilePhoto" alt="Profile Photo" class="profile-photo">
          <span>{{ userProfile.displayName || userProfile.name }}</span>
        </div>
        <textarea v-model="newCommentContent" placeholder="댓글" class="small-textarea"></textarea>
        <div class="file-upload">
          <label>사진</label>
          <input type="file" @change="handleFileUpload">
        </div>
        <button @click="submitComment" class="submit-button">확인</button>
      </div>
    </div>
  </div>
  <div v-else class="loading">
    <p>로딩 중...</p>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { doc, getDoc, updateDoc, collection, addDoc, getDocs, query, orderBy, runTransaction } from "firebase/firestore";
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage, auth } from "../firebase";
import { useStore } from 'vuex';
import { onAuthStateChanged } from "firebase/auth";
import UserProfileHeader from '../components/UserProfileHeader.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

export default {
  components: {
    UserProfileHeader,
    FontAwesomeIcon
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const post = ref(null);
    const comments = ref([]);
    const newCommentContent = ref('');
    const commentImageFile = ref(null);
    const userReaction = ref(null);
    const isReacting = ref(false);
    const renderComponent = ref(false);
    const defaultProfilePhoto = '/default-profile-photo.jpg';
    const userProfile = ref({
      uid: '',
      uniqueId: '',
      photoURL: '',
      displayName: '',
      name: ''
    });
    onMounted(() => {
      const postId = route.params.id;
      post.value = { id: postId };
    });

    const sharePost = async () => {
      try {
        const postUrl = `${window.location.origin}/post/${post.value.id}`;
        await navigator.clipboard.writeText(postUrl);
        alert("포스트 URL이 클립보드에 복사되었습니다.");
      } catch (error) {
        console.error("공유 실패:", error);
        alert("공유 중 오류가 발생했습니다.");
      }
    };

    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const renderedPostContent = computed(() => {
      return (post.value.content || '').replace(/\[img\](.*?)\[\/img\]/g, '<img src="$1" class="inserted-image" />');
    });

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('ko-KR', options);
    };

    const timeSince = (dateString) => {
      const now = new Date();
      const past = new Date(dateString);
      const seconds = Math.floor((now - past) / 1000);

      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1) return interval + "년 전";

      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) return interval + "달 전";

      interval = Math.floor(seconds / 86400);
      if (interval >= 1) return interval + "일 전";

      interval = Math.floor(seconds / 3600);
      if (interval >= 1) return interval + "시간 전";

      interval = Math.floor(seconds / 60);
      if (interval >= 1) return interval + "분 전";

      return "방금 전";
    };

    const handleFileUpload = (event) => {
      commentImageFile.value = event.target.files[0];
    };

    const uploadImage = async (file) => {
      if (!file) return '';
      const storageRefInstance = storageRef(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRefInstance, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          (error) => {
            console.error('Image upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    };

    const submitComment = async () => {
      if (!newCommentContent.value.trim()) {
        alert("내용을 입력하세요.");
        return;
      }

      let imageUrl = '';

      try {
        if (commentImageFile.value) {
          imageUrl = await uploadImage(commentImageFile.value);
        }

        let userData = {
          uid: '',
          uniqueId: '',
          displayName: '',
          photoURL: defaultProfilePhoto,
          name: ''
        };

        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            userData = userDocSnap.data();
            userData.uid = user.uid;
            userData.uniqueId = userData.uniqueId || user.uid;
            userData.displayName = userData.displayName || userData.name || '';
            userData.name = userData.name || '';
          } else {
            userData.uid = user.uid;
            userData.uniqueId = user.uid;
            userData.displayName = '';
            userData.name = '';
          }
        }

        const newComment = {
          user: {
            uid: userData.uid,
            uniqueId: userData.uniqueId,
            displayName: userData.displayName,
            photoURL: userData.photoURL,
            name: userData.name
          },
          content: newCommentContent.value,
          imageUrl: imageUrl,
          createdAt: new Date().toISOString(),
          likes: 0,
          dislikes: 0
        };

        const postId = route.params.postId;
        const commentsRef = collection(db, "posts", postId, "comments");
        await addDoc(commentsRef, newComment);
        comments.value.push(newComment);

        newCommentContent.value = '';
        commentImageFile.value = null;

        console.log("New comment added:", newComment);
      } catch (error) {
        console.error("Error adding comment: ", error);
      }
    };

    const fetchComments = async () => {
      const postId = route.params.postId;
      const commentsQuery = query(collection(db, "posts", postId, "comments"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(commentsQuery);
      comments.value = querySnapshot.docs.map(docSnap => {
        const data = docSnap.data();
        data.id = docSnap.id;
        return data;
      });
    };

    const renderedCommentContent = (content) => {
      return content.replace(/\[img\](.*?)\[\/img\]/g, '<img src="$1" class="inserted-image" />');
    };

    const handleReaction = async (reaction) => {
      if (!isAuthenticated.value) {
        alert("로그인 후 이용 가능합니다.");
        return;
      }

      if (isReacting.value) return;

      isReacting.value = true;

      const postId = route.params.postId;
      const userId = store.getters.userProfile.uid;
      const reactionRef = doc(db, "posts", postId, "reactions", userId);

      try {
        await runTransaction(db, async (transaction) => {
          const reactionDoc = await transaction.get(reactionRef);
          const postRef = doc(db, "posts", postId);

          if (!reactionDoc.exists()) {
            const newReaction = { type: reaction };
            transaction.set(reactionRef, newReaction);

            if (reaction === "like") {
              transaction.update(postRef, { likes: (post.value.likes || 0) + 1 });
              post.value.likes = (post.value.likes || 0) + 1;
              userReaction.value = reaction; // 즉시 반영
            } else {
              transaction.update(postRef, { dislikes: (post.value.dislikes || 0) + 1 });
              post.value.dislikes = (post.value.dislikes || 0) + 1;
              userReaction.value = reaction; // 즉시 반영
            }
          } else {
            const currentReaction = reactionDoc.data().type;
            if (currentReaction === reaction) {
              transaction.delete(reactionRef);

              if (reaction === "like") {
                transaction.update(postRef, { likes: (post.value.likes || 0) - 1 });
                post.value.likes = (post.value.likes || 0) - 1;
              } else {
                transaction.update(postRef, { dislikes: (post.value.dislikes || 0) - 1 });
                post.value.dislikes = (post.value.dislikes || 0) - 1;
              }
              userReaction.value = null; // 즉시 반영
            } else {
              transaction.update(reactionRef, { type: reaction });

              if (reaction === "like") {
                transaction.update(postRef, {
                  likes: (post.value.likes || 0) + 1,
                  dislikes: (post.value.dislikes || 0) - 1
                });
                post.value.likes = (post.value.likes || 0) + 1;
                post.value.dislikes = (post.value.dislikes || 0) - 1;
                userReaction.value = reaction; // 즉시 반영
              } else {
                transaction.update(postRef, {
                  dislikes: (post.value.dislikes || 0) + 1,
                  likes: (post.value.likes || 0) - 1
                });
                post.value.dislikes = (post.value.dislikes || 0) + 1;
                post.value.likes = (post.value.likes || 0) - 1;
                userReaction.value = reaction; // 즉시 반영
              }
            }
          }
        });
      } catch (error) {
        console.error("Failed to handle reaction: ", error);
      } finally {
        isReacting.value = false;
      }
    };

    const handleCommentReaction = async (commentId, reaction) => {
      if (!isAuthenticated.value) {
        alert("로그인 후 이용 가능합니다.");
        return;
      }

      const commentIndex = comments.value.findIndex(comment => comment.id === commentId);
      if (commentIndex === -1 || comments.value[commentIndex].isReacting) return;

      comments.value[commentIndex].isReacting = true;

      const postId = route.params.postId;
      const userId = store.getters.userProfile.uid;
      const commentReactionRef = doc(db, "posts", postId, "comments", commentId, "reactions", userId);

      try {
        await runTransaction(db, async (transaction) => {
          const reactionDoc = await transaction.get(commentReactionRef);
          const commentRef = doc(db, "posts", postId, "comments", commentId);

          if (!reactionDoc.exists()) {
            const newReaction = { type: reaction };
            transaction.set(commentReactionRef, newReaction);

            if (reaction === "like") {
              transaction.update(commentRef, { likes: (comments.value[commentIndex].likes || 0) + 1 });
              comments.value[commentIndex].likes = (comments.value[commentIndex].likes || 0) + 1;
              comments.value[commentIndex].userReaction = reaction; // 즉시 반영
            } else {
              transaction.update(commentRef, { dislikes: (comments.value[commentIndex].dislikes || 0) + 1 });
              comments.value[commentIndex].dislikes = (comments.value[commentIndex].dislikes || 0) + 1;
              comments.value[commentIndex].userReaction = reaction; // 즉시 반영
            }
          } else {
            const currentReaction = reactionDoc.data().type;
            if (currentReaction === reaction) {
              transaction.delete(commentReactionRef);

              if (reaction === "like") {
                transaction.update(commentRef, { likes: (comments.value[commentIndex].likes || 0) - 1 });
                comments.value[commentIndex].likes = (comments.value[commentIndex].likes || 0) - 1;
              } else {
                transaction.update(commentRef, { dislikes: (comments.value[commentIndex].dislikes || 0) - 1 });
                comments.value[commentIndex].dislikes = (comments.value[commentIndex].dislikes || 0) - 1;
              }
              comments.value[commentIndex].userReaction = null; // 즉시 반영
            } else {
              transaction.update(commentReactionRef, { type: reaction });

              if (reaction === "like") {
                transaction.update(commentRef, {
                  likes: (comments.value[commentIndex].likes || 0) + 1,
                  dislikes: (comments.value[commentIndex].dislikes || 0) - 1
                });
                comments.value[commentIndex].likes = (comments.value[commentIndex].likes || 0) + 1;
                comments.value[commentIndex].dislikes = (comments.value[commentIndex].dislikes || 0) - 1;
                comments.value[commentIndex].userReaction = reaction; // 즉시 반영
              } else {
                transaction.update(commentRef, {
                  dislikes: (comments.value[commentIndex].dislikes || 0) + 1,
                  likes: (comments.value[commentIndex].likes || 0) - 1
                });
                comments.value[commentIndex].dislikes = (comments.value[commentIndex].dislikes || 0) + 1;
                comments.value[commentIndex].likes = (comments.value[commentIndex].likes || 0) - 1;
                comments.value[commentIndex].userReaction = reaction; // 즉시 반영
              }
            }
          }
        });
      } catch (error) {
        console.error("Failed to handle comment reaction: ", error);
      } finally {
        comments.value[commentIndex].isReacting = false;
      }
    };

    const fetchUserReaction = async () => {
      if (!isAuthenticated.value) return;

      const postId = route.params.postId;
      const userId = store.getters.userProfile.uid;
      const reactionRef = doc(db, "posts", postId, "reactions", userId);
      const reactionDoc = await getDoc(reactionRef);

      if (reactionDoc.exists()) {
        userReaction.value = reactionDoc.data().type;
      } else {
        userReaction.value = null;
      }
    };

    const setPost = (postData) => {
      // Ensure user object contains uniqueId or uid
      postData.user = postData.user || {};
      postData.user.uniqueId = postData.user.uniqueId || postData.user.uid;

      post.value = {
        ...postData,
        likes: postData.likes || 0,
        dislikes: postData.dislikes || 0,
        user: postData.user
      };
      renderComponent.value = true;

      // Check if uniqueId is correctly set
      console.log('Set post:', post.value);
    };

    const refreshUserProfile = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            userProfile.value.uid = user.uid;
            userProfile.value.uniqueId = userData.uniqueId || user.uid;
            userProfile.value.photoURL = userData.photoURL || defaultProfilePhoto;
            userProfile.value.displayName = userData.displayName || userData.name || '';
            userProfile.value.name = userData.name || '';
          } else {
            userProfile.value.uid = user.uid;
            userProfile.value.uniqueId = user.uid;
            userProfile.value.photoURL = defaultProfilePhoto;
            userProfile.value.displayName = '';
            userProfile.value.name = '';
          }
        } else {
          userProfile.value.uid = '';
          userProfile.value.uniqueId = '';
          userProfile.value.photoURL = defaultProfilePhoto;
          userProfile.value.displayName = '';
          userProfile.value.name = '';
        }
        renderComponent.value = true;
      });
    };

    const goToUserProfile = (uniqueId) => {
      if (uniqueId && uniqueId !== 'anonymous') {
        router.push({ name: 'UserProfile', params: { uniqueId } });
      } else {
        console.error("uniqueId is missing or invalid");
      }
    };

    const handleProfileClick = (uniqueId) => {
      try {
        goToUserProfile(uniqueId);
      } catch (error) {
        console.error("Error navigating to user profile:", error);
        alert("사용자 프로필 페이지로 이동 중 오류가 발생했습니다. 콘솔을 확인하세요.");
      }
    };

    const handleCommentUserClick = (comment) => {
      try {
        goToUserProfile(comment.user?.uniqueId || comment.user?.uid);
      } catch (error) {
        console.error("Error navigating to comment user profile:", error);
        alert("댓글 작성자 프로필 페이지로 이동 중 오류가 발생했습니다. 콘솔을 확인하세요.");
      }
    };

    onMounted(async () => {
      try {
        const docRef = doc(db, "posts", route.params.postId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const post = { id: docSnap.id, ...docSnap.data() };

          await updateDoc(docRef, {
            views: (post.views || 0) + 1
          });

          post.views += 1;

          // Ensure user object contains uniqueId or uid
          post.user = post.user || {};
          post.user.uniqueId = post.user.uniqueId || post.user.uid;

          setPost(post);
          await fetchComments();
          await fetchUserReaction();
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
      await store.dispatch('initializeAuthState');
      await refreshUserProfile();
    });
    

    return {
      post,
      comments,
      newCommentContent,
      commentImageFile,
      userReaction,
      isReacting,
      renderComponent,
      defaultProfilePhoto,
      userProfile,
      isAuthenticated,
      renderedPostContent,
      formatDate,
      handleFileUpload,
      uploadImage,
      submitComment,
      fetchComments,
      renderedCommentContent,
      handleReaction,
      handleCommentReaction,
      fetchUserReaction,
      handleProfileClick,
      handleCommentUserClick,
      timeSince,
       post,
      sharePost,
    };
  }
};
</script>

<style scoped>
.reaction-button.like.active {
  color: blue;
}
.reaction-button.dislike.active {
  color: red;
}
.comment-reactions {
  display: flex;
  align-items: center;
}
.new-comment .small-textarea {
  width: 80%;
  height: 50px; /* 원하는 높이로 설정 */
  resize: none; /* 크기 조절 불가능하게 설정 */
  margin-bottom: 10px;
}
.reactions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.reaction-button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
  color: gray;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.post-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px; /* 최대 너비를 늘림 */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-content {
  display: flex;
  flex-direction: column;
  
  width: 100%;
  
}

.post-content p {
  margin: 0 0 10px;
}

.thumbnail-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 5px;
}



.post-views {
  margin-top: 10px;
}

.reactions {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}

.reactions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.reactions button:focus {
  outline: none;
}

.comments-section {
  width: 100%;
  margin-top: 20px;
}

.comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.comment .profile-photo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.comment-content {
  display: flex;
  flex-direction: column;
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.comment-image {
  margin-top: 10px;
  width: 100%; /* 댓글 이미지 크기를 화면 너비의 100%로 설정 */
  max-width: 1600px; /* 최대 너비를 1600px로 설정 */
  height: auto;
  border-radius: 5px;
  margin-left: 0; /* 이미지 왼쪽 정렬 */
}

.new-comment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
}

.new-comment textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.new-comment .file-upload {
  margin-bottom: 10px;
}

.new-comment button {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .post-content,
  .comment-image,
  .inserted-image {
    max-width: 100%;
  }
}
</style>



























