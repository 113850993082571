<template>
  <div class="login-container" v-if="!isUserLoggedIn">
    <button @click="signInWithGoogle" class="google-login-btn">
      <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google Logo" class="google-logo"/>
      <!-- 버튼 텍스트 동적으로 변경 -->
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';

export default {
  setup() {
    const router = useRouter();
    const isUserLoggedIn = ref(false);
    // 버튼 텍스트를 저장할 반응형 데이터
    const buttonText = ref('');

    const auth = getAuth();

    // 사용자 로그인 상태 및 브라우저 언어 설정 확인
    onMounted(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          isUserLoggedIn.value = true;
          router.push({ name: 'About' });
        } else {
          isUserLoggedIn.value = false;
        }
      });
      // 버튼 텍스트 설정
      setButtonText();
    });

    // 브라우저 언어에 따라 버튼 텍스트 설정
    function setButtonText() {
      const userLang = navigator.language || navigator.userLanguage;
      buttonText.value = userLang.startsWith('ko') ? 'Google로 로그인' : 'Sign in with Google';
    }

    const signInWithGoogle = async () => {
      try {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        isUserLoggedIn.value = true;
        router.push({ name: 'About' });
      } catch (error) {
        console.error(error);
      }
    };

    return {
      signInWithGoogle,
      isUserLoggedIn,
      buttonText, // 템플릿에서 사용할 수 있도록 반환
    };
  }
};
</script>





<!-- 스타일은 변경 없이 유지 -->


<style>


.google-login-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: white;
  color: black;
  padding: 6px 12px; /* 크기 조정 */
  border: 1px solid black; /* 테두리 추가 */
  border-radius: 4px; /* 테두리 둥글게 */
  font-size: 14px; /* 크기 조정 */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.google-login-btn:hover {
  color: black;
}

.google-logo {
  margin-right: 6px; /* 로고 간격 조정 */
  height: 15px; /* 로고 크기 조정 */
}


</style>

