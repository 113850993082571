<template>
  <div class="boards">
    <h2>게시판 목록</h2>
    <div class="search-bar">
      <input type="text" v-model="searchQuery" placeholder="검색어를 입력하세요" @input="filterBoards" />
    </div>
    <div v-for="board in filteredBoards" :key="board.id" class="board">
      <router-link :to="{ name: 'BoardDetail', params: { boardId: board.id } }" class="board-link">
        <div class="thumbnail">
          <img :src="board.thumbnailUrl || 'default-thumbnail.jpg'" alt="Thumbnail Image" class="thumbnail-image" />
        </div>
        <div class="board-info">
          <h3>{{ board.title }}</h3>
          <p>{{ board.description }}</p>
        </div>
      </router-link>
    </div>
    <div class="create-board-button">
      <router-link to="/create-board">
        <button>새 게시판 생성</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

export default {
  name: 'BoardList',
  data() {
    return {
      boards: [],
      searchQuery: '',
      filteredBoards: []
    };
  },
  methods: {
    async fetchBoards() {
      const boardsQuery = collection(db, "boards");
      const querySnapshot = await getDocs(boardsQuery);
      this.boards = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      this.filteredBoards = this.boards; // 초기에는 모든 게시판을 보여줍니다.
    },
    filterBoards() {
      const query = this.searchQuery.toLowerCase();
      this.filteredBoards = this.boards.filter(board =>
        board.title.toLowerCase().includes(query) || 
        board.description.toLowerCase().includes(query)
      );
    }
  },
  async created() {
    await this.fetchBoards();
  }
};
</script>

<style scoped>
.boards {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.boards h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.board {
  background: #fff;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.board-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.thumbnail {
  margin-right: 20px;
}

.thumbnail-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
}

.board-info {
  display: flex;
  flex-direction: column;
}

.create-board-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.create-board-button button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-board-button button:hover {
  background-color: #0056b3;
}
</style>


