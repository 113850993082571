// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // getStorage import 추가

// Firebase 설정 정보
const firebaseConfig = {
  // ...firebase 설정 정보...
  apiKey: "AIzaSyBws5vbmKYAnRq6Ji3Pf-nGp5G09jMEuA8",
  apiKey: "AIzaSyD-UdrbTRjye0OrreENepa_d7RS6rcVaF8",
  authDomain: "tupo-a2cba.firebaseapp.com",
  databaseURL: "https://tupo-a2cba-default-rtdb.firebaseio.com",
  projectId: "tupo-a2cba",
  storageBucket: "tupo-a2cba.appspot.com",
  messagingSenderId: "770828873026",
  appId: "1:770828873026:web:ce5b8d608bbb394fdf2cdb",
  measurementId: "G-XQYZMVCRPE"
};

// Firebase 앱 초기화
const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp); // 수정: 'app'을 'firebaseApp'으로 변경




