<template>
  <div class="board-detail" v-if="board">
    <h2 class="rainbow-text">{{ board.title }}</h2>
    <p>{{ board.description }}</p>
    <div class="edit-button" v-if="isAuthenticated">
      <button @click="toggleCreatePostForm">+</button>
    </div>
    <div v-if="showCreatePostForm" class="create-post">
      <CreatePost :boardId="boardId" @postCreated="addPostToList" @closeForm="toggleCreatePostForm" />
    </div>
    <div class="search-bar">
      <input type="text" v-model="searchQuery" placeholder="검색어를 입력하세요" @input="filterPosts" />
    </div>
    <div class="posts">
      <div v-for="post in sortedAndFilteredPosts" :key="post.id" class="post">
        <router-link :to="{ name: 'PostView', params: { boardId: boardId, postId: post.id } }" class="post-link">
          <div class="post-header">
            <img :src="post.user?.photoURL || 'default-profile-photo.jpg'" alt="Profile Photo" class="profile-photo">
            <span>{{ post.user?.uniqueId || 'Unknown' }}</span>
            <div class="post-likes">
              <small>추천: {{ post.likes || 0 }}</small>
            </div>
          </div>
          <div class="post-body">
            <img v-if="post.thumbnailUrl" :src="post.thumbnailUrl" alt="Thumbnail Image" class="thumbnail-image" />
            <div class="post-info">
              <h3>{{ post.title }} (추천: {{ post.likes || 0 }})</h3>
              <small>{{ formatDate(post.createdAt) }}</small>
              <div class="post-views">
                <small>조회수: {{ post.views }}</small>
              </div>
            </div>
          </div>
        </router-link>
        <button v-if="isBoardCreator" @click="deletePost(post.id)" class="delete-button">
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
    <div class="board-list-button">
      <router-link to="/boards">
        <button>다른 게시판으로 이동</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, collection, getDocs, query, deleteDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import CreatePost from './CreatePost.vue';

export default {
  name: 'BoardDetail',
  components: {
    CreatePost
  },
  data() {
    return {
      board: null,
      posts: [],
      boardId: this.$route.params.boardId,
      searchQuery: '',
      isBoardCreator: false,
      showCreatePostForm: false
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    sortedAndFilteredPosts() {
      const filteredPosts = this.filterPosts();
      return filteredPosts.sort((a, b) => this.calculatePostScore(b) - this.calculatePostScore(a));
    }
  },
  methods: {
    toggleCreatePostForm() {
      this.showCreatePostForm = !this.showCreatePostForm;
    },
    async fetchBoard() {
      const boardDoc = doc(db, "boards", this.boardId);
      const boardSnapshot = await getDoc(boardDoc);
      if (boardSnapshot.exists()) {
        this.board = boardSnapshot.data();
        const user = auth.currentUser;
        this.isBoardCreator = user && user.uid === this.board.creatorUid;
      }
    },
    async fetchPosts() {
      const postsQuery = query(collection(db, "boards", this.boardId, "posts"));
      const querySnapshot = await getDocs(postsQuery);
      this.posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    },
    addPostToList(post) {
      this.posts.push(post);
    },
    async deletePost(postId) {
      if (confirm("정말로 이 게시물을 삭제하시겠습니까?")) {
        try {
          await deleteDoc(doc(db, "boards", this.boardId, "posts", postId));
          this.posts = this.posts.filter(post => post.id !== postId);
          alert("게시물이 삭제되었습니다.");
        } catch (error) {
          console.error("게시물 삭제 중 오류가 발생했습니다: ", error);
        }
      }
    },
    formatDate(dateString) {
      const options = { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('ko-KR', options);
    },
    filterPosts() {
      if (!this.searchQuery) {
        return this.posts;
      }
      const query = this.searchQuery.toLowerCase();
      return this.posts.filter(post => 
        post.title.toLowerCase().includes(query) ||
        (post.content && post.content.toLowerCase().includes(query))
      );
    },
    calculatePostScore(post) {
      const now = new Date();
      const postDate = new Date(post.createdAt);
      const timeDiff = (now - postDate) / (1000 * 60 * 60); // 시간 단위 차이
      const freshnessScore = 5 / (1 + timeDiff); // 시간이 지남에 따라 점수가 감소, 가중치를 5로 설정
      const popularityScore = (post.likes || 0) + (post.comments || 0) * 0.5 + (post.views || 0) * 0.1; // 좋아요, 댓글, 조회수를 반영한 가중치 점수
      return freshnessScore + popularityScore;
    }
  },
  async created() {
    await this.fetchBoard();
    await this.fetchPosts();
  },
  watch: {
    '$route.params.boardId': 'fetchPosts'
  }
};
</script>


<style scoped>
.rainbow-text {
  font-size: 2em;
  font-weight: bold;
  background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
  color: transparent;
  background-clip: text;
}

.board-detail {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.edit-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.edit-button button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.edit-button button:hover {
  background-color: #0056b3;
}

.search-bar {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-bar input {
  width: 80%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 30px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.search-bar input::placeholder {
  color: #aaa;
}

.search-bar input:focus {
  border-color: #999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.board-list-button {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.board-list-button button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.board-list-button button:hover {
  background-color: #218838;
}

.posts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.post {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  padding: 15px;
}

.post:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.post-header .profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-header .post-likes {
  margin-left: auto;
}

.post-header .post-likes.top-post-likes small {
  color: red;
}

.post-header span {
  font-weight: bold;
}

.post-body {
  display: flex;
  flex-direction: column;
}

.thumbnail-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.post-info h3 {
  margin: 0;
  font-size: 1.2em;
}

.post-info small {
  color: #999;
}

.post-views small {
  color: #999;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>





