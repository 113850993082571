<template>
  <div class="post-box-container">
    <div class="post-box" v-if="posts.length > 0">
      <img :src="currentPostImage" alt="Profile Photo" class="profile-photo">
      <div class="post-info">
        <router-link :to="{ name: 'PostDetail', params: { postId: currentPostId } }">
          <h4>{{ currentPostTitle }}</h4>
        </router-link>
      </div>
    </div>
    <div v-else>
      <p>게시물이 없습니다.</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';

export default {
  name: 'Box',
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const currentPostIndex = ref(0);

    const updatePostIndex = () => {
      if (props.posts.length > 0) {
        currentPostIndex.value = (currentPostIndex.value + 1) % props.posts.length;
      }
    };

    const currentPostTitle = computed(() => {
      if (props.posts.length > 0) {
        return props.posts[currentPostIndex.value].title || '제목 없음';
      }
      return '제목 없음';
    });

    const currentPostId = computed(() => {
      if (props.posts.length > 0) {
        return props.posts[currentPostIndex.value].id;
      }
      return null;
    });

    const currentPostImage = computed(() => {
      if (props.posts.length > 0) {
        return props.posts[currentPostIndex.value].user?.photoURL || 'default-profile-photo.jpg';
      }
      return 'default-profile-photo.jpg';
    });

    let interval = null;

    onMounted(() => {
      interval = setInterval(updatePostIndex, 5000);
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    watch(props.posts, (newPosts) => {
      if (newPosts.length > 0) {
        currentPostIndex.value = 0;  // 새 게시물이 추가되면 인덱스를 0으로 초기화
      }
    });

    return {
      currentPostTitle,
      currentPostId,
      currentPostImage,
    };
  },
};
</script>

<style scoped>
.post-box-container {
  margin: 20px 0;
}

.post-box {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.profile-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.post-info {
  display: flex;
  flex-direction: column;
}

.post-info h4 {
  margin: 0;
}
</style>




