<template>
  <div class="page-container">
    <div class="profile-container" :style="containerStyle">
      <button @click="shareProfile" class="share-button">
        <font-awesome-icon icon="share" size="lg" />
      </button>
      <img :src="userProfile.photoURL || defaultPicture" alt="User's profile picture" class="profile-picture" />
      <div class="user-info">
        <h2>{{ userProfile.name }}</h2>
        <p>{{ userProfile.uniqueId }}</p>
        <span class="temperature-indicator" :style="{ backgroundColor: temperatureColor }">
          {{ temperature }}℃
        </span>
        <BioComponent v-if="userProfile.uid" :userId="userProfile.uid" />
        <div class="actions" v-if="userReaction !== 'loading'">
          <button :class="{ 'active': userReaction === 'like', 'like': userReaction === 'like' }" @click="toggleReaction('like')">
            <font-awesome-icon icon="thumbs-up" size="2x" />
            <span class="reaction-count">{{ formatNumber(reactionsCount.likes) }}</span>
          </button>
          <button :class="{ 'active': userReaction === 'dislike', 'dislike': userReaction === 'dislike' }" @click="toggleReaction('dislike')">
            <font-awesome-icon icon="thumbs-down" size="2x" />
            <span class="reaction-count">{{ formatNumber(reactionsCount.dislikes) }}</span>
          </button>
        </div>
        <div class="loader" v-if="userReaction === 'loading'"></div>
      </div>
     
      
      <!-- 구글 애드센스 광고 블록 추가 -->
      <div class="adsense-ad">
        <ins class="adsbygoogle"
             style="display:block"
             data-ad-format="fluid"
             data-ad-layout-key="-fb+5w+4e-db+86"
             data-ad-client="ca-pub-9922969088623336"
             data-ad-slot="7094842952"></ins>
      </div>

      <UserComments :uniqueId="userProfile.uid" />
    </div>
    <div class="content-container">
      <!-- 기타 컨텐츠를 여기에 추가 -->
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faThumbsUp, faThumbsDown, faShare } from '@fortawesome/free-solid-svg-icons';
import UserComments from './UserComments.vue';

import BioComponent from '@/components/BioComponent.vue'; // BioComponent 가져오기
library.add(faThumbsUp, faThumbsDown, faShare);

export default {
  
  components: {
    FontAwesomeIcon,
    UserComments,
    
    BioComponent, // BioComponent 등록하기
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const uniqueId = computed(() => route.params.uniqueId);
    const userProfile = computed(() => store.state.userProfile);
    const reactionsCount = computed(() => store.getters.reactionsCount);
    const userReaction = ref('loading');
    const isOwner = computed(() => store.state.userProfile.uid === uniqueId.value);
    const isLoggedIn = computed(() => store.state.isAuthenticated);

    // 기본 프로필 사진 URL 설정
    const defaultPicture = 'path/to/default-picture.jpg';

    const shareProfile = () => {
      const url = `https://tupo.kr/user/${uniqueId.value}`;

      if (navigator.clipboard) {
        navigator.clipboard.writeText(url).then(() => {
          alert("URL이 클립보드에 복사되었습니다!");
        }).catch(err => {
          console.error('클립보드 복사 실패:', err);
          fallbackCopyToClipboard(url);
        });
      } else {
        fallbackCopyToClipboard(url);
      }
    };

    const fallbackCopyToClipboard = (url) => {
      const textarea = document.createElement('textarea');
      textarea.value = url;
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'URL이 클립보드에 복사되었습니다!' : '클립보드 복사에 실패했습니다.';
        alert(msg);
      } catch (err) {
        console.error('클립보드 복사 실패:', err);
        alert("클립보드를 지원하지 않는 브라우저입니다. URL을 직접 복사해 주세요.");
      } finally {
        document.body.removeChild(textarea);
      }
    };

    onBeforeUnmount(() => {
      store.dispatch('resetUserProfile');
    });

    const containerStyle = computed(() => {
      if (!isLoggedIn.value) {
        return {

        };
      }
      return {};
    });

    const fetchUserData = async () => {
      if (uniqueId.value) {
        const id = uniqueId.value;
        await store.dispatch('fetchUserProfile', id);
        await store.dispatch('fetchReactionsCount', id);
        if (store.state.isAuthenticated) {
          userReaction.value = await store.dispatch('fetchUserReaction', { uniqueId: id }) || '';
        } else {
          userReaction.value = '';
        }
      }
    };

    onMounted(fetchUserData);

    watch(() => route.params.uniqueId, () => {
      userReaction.value = 'loading';
      fetchUserData();
    });

    const toggleReaction = async (reaction) => {
      if (!store.state.isAuthenticated) {
        alert("이 기능을 사용하기 위해서는 로그인이 필요합니다.");
        userReaction.value = '';
        return;
      }
      const newReaction = userReaction.value === reaction ? '' : reaction;
      await store.dispatch('toggleReaction', { uniqueId: uniqueId.value, reaction: newReaction });
      userReaction.value = newReaction;
      await store.dispatch('fetchReactionsCount', uniqueId.value);
    };

    const temperature = computed(() => {
      const temp = reactionsCount.value.likes - reactionsCount.value.dislikes;
      return Math.max(0, Math.min(100, temp));
    });

    const temperatureColor = computed(() => {
      if (temperature.value > 0) {
        return `rgb(255, ${155 - Math.min(temperature.value * 5, 155)}, 0)`;
      } else {
        return `rgb(0, ${155 - Math.min(Math.abs(temperature.value) * 5, 155)}, 255)`;
      }
    });

    // 유틸리티 함수 추가
    function formatNumber(number) {
      if (number >= 1000000) {
        return (number / 1000000).toFixed(1) + 'M';
      }
      if (number >= 1000) {
        return (number / 1000).toFixed(1) + 'k';
      }
      return number;
    }

    return {
      userProfile,
      reactionsCount,
      userReaction,
      temperature,
      temperatureColor,
      containerStyle,
      toggleReaction,
      shareProfile,
      formatNumber,
      isOwner, // isOwner 반환
      isLoggedIn, // isLoggedIn 반환
      defaultPicture, // defaultPicture 반환
    };
  },
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.content-container {
  margin-top: 20px; /* 프로필 컨테이너와 기타 컨텐츠 사이 여백 */
  width: 100%;
}

.share-button {
  position: fixed;
  right: 10px;
  top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; /* 더 높은 z-index 설정 */
}
.share-button:hover {
  background-color: #0056b3;
}
.temperature-indicator {
  width: 27px;
  height: 27px;
  font-size: 12px;
  line-height: 27px;
  color: white;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  margin-left: 10px;
  display: inline-block;
}
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-picture {
  width: 85px;
  height: 85px;
  border-radius: 75px;
  margin-top: 30px;
}
.user-info {
  overflow: auto;
  text-align: center;
  position: static;
  background-color: white;
  z-index: 1000;
}
.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.actions button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #c0c0c0;
}
.actions button.like:hover,
.actions button.like.active,
.actions button.dislike:hover,
.actions button.dislike.active {
  transform: scale(1.1);
}
.actions button.like.active {
  color: #007bff;
  transform: scale(1.1);
}
.actions button.like:not(.active):hover {
  color: #007bff;
}
.actions button.dislike.active {
  color: #dc3545;
}
.reaction-count {
  margin-top: 4px;
  font-size: 0.75em;
}
.actions button .fa {
  font-size: 24px;
}
.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  animation: spin 2s linear infinite;
  margin: auto;
  display: block;
}
@media (max-width: 600px) {
  .share-button {
    right: 5px;
    top: 5px;
    padding: 6px 12px;
    font-size: 1.0rem;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>























