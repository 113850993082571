<template>
  <div :style="containerStyle">
    <!-- 게시물 목록 -->
    <h2>게시물 목록</h2>
    <div class="board-posts-container">
      <div v-if="boardPosts.length">
        <ul>
          <li v-for="post in boardPosts" :key="post.id">
            <div class="post-item">
              <img :src="post.postThumbnail" alt="Post Thumbnail" class="thumbnail-image post-thumbnail" />
              <img :src="post.user?.photoURL || defaultProfilePhoto" alt="Profile Photo" class="profile-photo">
              <div class="post-info">
                <router-link :to="{ name: 'PostView', params: { boardId: post.boardId, postId: post.id } }" class="post-title-link">
                  {{ post.title }}
                  <img :src="post.boardThumbnail" alt="Board Thumbnail" class="thumbnail-image board-thumbnail-larger" />
                </router-link>
                <p>작성일: {{ formatDate(post.createdAt) }}</p>
              </div>
              <div class="likes-info">
                <span>{{ post.likes }}</span>
                <i class="fas fa-thumbs-up"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else>
        <p>게시물이 없습니다.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../firebase';

// 데이터와 상태 정의
const boardPosts = ref([]);
const containerStyle = ref({ padding: '15px' }); // 동적 스타일
const defaultProfilePhoto = '/default-profile-photo.jpg';

// 게시물 데이터를 가져오는 함수
const fetchBoardPosts = async () => {
  try {
    const boardsQuery = query(collection(db, 'boards'));
    const boardsSnapshot = await getDocs(boardsQuery);

    let posts = [];
    for (const boardDoc of boardsSnapshot.docs) {
      const boardId = boardDoc.id;
      const boardData = boardDoc.data();
      const boardPostsQuery = query(collection(db, 'boards', boardId, 'posts'));
      const boardPostsSnapshot = await getDocs(boardPostsQuery);

      boardPostsSnapshot.forEach((postDoc) => {
        const postData = postDoc.data();
        const post = {
          id: postDoc.id,
          boardId,
          ...postData,
          boardTitle: boardData.title,
          boardThumbnail: boardData.thumbnailUrl || 'default-thumbnail.jpg',
          postThumbnail: postData.thumbnailUrl || 'default-thumbnail.jpg',
          user: postData.user || { photoURL: defaultProfilePhoto, uniqueId: 'Unknown' },
          likes: postData.likes || 0, // 좋아요 수
          createdAt: postData.createdAt, // 생성일
          score: calculatePostScore(postData), // 점수 계산
        };
        posts.push(post);
      });
    }

    // 점수를 기준으로 내림차순 정렬
    boardPosts.value = posts.sort((a, b) => b.score - a.score);
  } catch (error) {
    console.error('게시물 가져오기 중 오류 발생: ', error);
  }
};

// 점수를 계산하는 함수 (최신성 및 추천성 반영)
const calculatePostScore = (post) => {
  const now = new Date();
  const postDate = new Date(post.createdAt);
  const timeDiffInHours = (now - postDate) / (1000 * 60 * 60); // 시간 단위 차이
  const daysPassed = timeDiffInHours / 24; // 일 단위로 변환

  const freshnessScore = Math.max(0, 5 - daysPassed); // 하루 지나면 1점씩 차감, 5일 후에는 0점
  const popularityScore = (post.likes || 0); // 좋아요 수만 반영한 점수

  return freshnessScore + popularityScore;
};

// 날짜 형식을 포맷하는 함수
const formatDate = (date) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

// 컴포넌트가 마운트될 때 게시물 데이터를 가져옴
onMounted(() => {
  fetchBoardPosts();
});
</script>

<style scoped>
.board-posts-container {
  padding: 15px;
}

.post-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.post-item:hover {
  background-color: #e0e0e0;
}

.thumbnail-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.board-thumbnail-larger {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 4px;
  margin-left: 10px;
}

.post-thumbnail {
  margin-right: 10px;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.post-info {
  flex-grow: 1;
}

.post-title-link {
  display: flex;
  align-items: center;
}

.post-info a {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.post-info a:hover {
  text-decoration: underline;
  color: #007BFF;
}

.post-info p {
  margin: 5px 0;
  color: #555;
  font-size: 0.9em;
}

.likes-info {
  display: flex;
  align-items: center;
  font-size: 1.1em;
  font-weight: bold;
  color: #007BFF;
}

.likes-info i {
  margin-left: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 15px;
}

/* 반응형 디자인 */
@media (max-width: 600px) {
  .post-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .likes-info {
    margin-top: 10px;
    align-self: flex-end;
  }

  .post-info {
    margin-top: 10px;
  }
}
</style>














