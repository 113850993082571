<template>
  <div class="profile-container" v-if="userProfile">
    <img
      :src="userProfile.photoURL || defaultPicture"
      alt="프로필 사진"
      class="profile-picture"
      @click="goToUserProfile"
    />
    <input type="file" @change="uploadImage" hidden ref="fileInput" />
    <button class="profile-edit-btn" @click="triggerFileInput">+</button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

export default {
  name: 'Profile',
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = store.getters.user; // Vuex에서 로그인한 사용자 정보 가져오기
    const userProfile = ref(null);
    const defaultPicture = "https://via.placeholder.com/150"; // 기본 프로필 사진 URL
    const fileInput = ref(null); // 파일 입력 참조
    const storage = getStorage(); // Firebase Storage 인스턴스

    // 파일 입력 창을 트리거하는 메서드
    const triggerFileInput = () => {
      fileInput.value.click();
    };

    // 이미지 업로드 처리 메서드
    const uploadImage = async (event) => {
      const file = event.target.files[0];
      if (!file) return;
      const userId = user.uid; // 사용자 uid 사용
      const imageRef = storageRef(storage, `profileImages/${userId}`); // Storage 경로 참조 생성
      let photoURL = '';

      try {
        // 파일을 Firebase Storage에 업로드
        await uploadBytes(imageRef, file);
        // 업로드된 파일의 URL 가져오기
        photoURL = await getDownloadURL(imageRef);
        // Firestore에 사용자의 프로필 사진 URL 업데이트
        await updateDoc(doc(db, "users", userId), { photoURL });
        // Vuex 스토어의 사용자 프로필 사진 상태 업데이트
        store.commit('SET_PROFILE_PICTURE', { uid: userId, photoURL });
        // 로컬 상태 업데이트
        userProfile.value.photoURL = photoURL;
      } catch (error) {
        if (error.code === 'not-found') {
          // Firestore 문서가 없는 경우 새로 생성
          try {
            await setDoc(doc(db, "users", userId), { photoURL });
            store.commit('SET_PROFILE_PICTURE', { uid: userId, photoURL });
            userProfile.value.photoURL = photoURL;
          } catch (setError) {
            console.error('Failed to create user document: ', setError);
          }
        } else {
          console.error('Failed to upload image: ', error);
        }
      }
    };

    // 사용자 프로필 페이지로 이동하는 메서드
    const goToUserProfile = () => {
      const uniqueId = userProfile.value ? userProfile.value.uniqueId : null;
      if (uniqueId) {
        router.push({ name: 'UserProfile', params: { uniqueId } });
      } else {
        console.error('ID not found');
      }
    };

    // 컴포넌트 마운트 시 실행되는 로직
    onMounted(async () => {
      try {
        if (user && user.uid) {
          const userDocRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            userProfile.value = docSnap.data();
            userProfile.value.uniqueId = docSnap.data().uniqueId || user.uid; // Firestore 문서에서 uniqueId를 가져오거나 UID를 사용
            console.log('User profile loaded:', userProfile.value);
          } else {
            // Firestore에 사용자 정보가 없는 경우 기본값 설정
            userProfile.value = {
              name: user.displayName || 'Anonymous',
              photoURL: user.photoURL || defaultPicture,
              uniqueId: user.uid // 기본적으로 UID를 uniqueId로 사용
            };
            console.log('Default user profile set:', userProfile.value);
            // Firestore에 기본 사용자 프로필 정보 저장
            await setDoc(userDocRef, userProfile.value);
          }
        } else {
          console.error('User not logged in or user ID not found');
        }
      } catch (error) {
        console.error('Error loading user profile:', error);
      }
    });

    return {
      userProfile,
      defaultPicture,
      fileInput,
      triggerFileInput,
      uploadImage,
      goToUserProfile,
    };
  },
};
</script>









<style scoped>

.profile-container {
  position: fixed; /* 항상 뷰포트에 대해서 고정되어 있음 */
  top: 10px; /* 상단으로부터의 거리 */
  right: 10px; /* 오른쪽으로부터의 거리 */
  z-index: 1000; /* 다른 요소들 위에 레이어링 */
  /* width와 max-width 설정이 필요할 수 있음 */
}
.profile-picture:active {
  transform: scale(0.9); /* 클릭시 이미지를 축소하는 효과를 줍니다. */
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  transition: transform 0.2s; /* 클릭 이펙트에 애니메이션을 추가합니다. */
  margin-top: -4px; /* 프로필 사진을 아래로 내리기 위해 양수값으로 설정 */
}

.user-info {
  margin-top: 8px;
}

.username {
  font-weight: bold;
}

.userid {
  display: block;
  font-size: 0.8em;
}

.profile-edit-btn {
  position: absolute;
  top: 35px;
  right: 4px; /* 오른쪽 정렬 */
  background-color: #ff0000;
  color: #fff;
  border-radius: 50%;
  border: 1px solid #ddd;
  padding: 5px;
  cursor: pointer;
  z-index: 10;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* 모바일 환경에 맞는 스타일 조정 */
@media (max-width: 768px) { /* 모바일 화면 크기에 따라 적용 */
  .profile-container {
    position: fixed; /* 고정 위치 */
    top: 10px; /* 상단에서 10px 떨어진 위치 */
    right: 10px; /* 오른쪽에서 10px 떨어진 위치 */
    z-index: 1000; /* 다른 요소들 위에 표시 */
  }
}
</style>



