<template>
  <div class="create-board">
    <h2>게시판 생성</h2>
    <input v-model="boardTitle" placeholder="게시판 이름을 입력하세요" />
    <textarea v-model="boardDescription" placeholder="게시판 설명을 입력하세요"></textarea>
    <div class="file-upload">
      <label>게시판 썸네일 업로드:</label>
      <input type="file" @change="handleFileUpload" />
    </div>
    <div v-if="thumbnailUrl" class="thumbnail-preview">
      <img :src="thumbnailUrl" alt="Thumbnail Preview" class="thumbnail-image" />
    </div>
    <button :disabled="isButtonDisabled" @click="createBoard">게시판 생성</button>
  </div>
</template>

<script>
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { db, storage, auth } from "../firebase";

export default {
  name: 'CreateBoard',
  data() {
    return {
      boardTitle: '',
      boardDescription: '',
      thumbnailFile: null,
      thumbnailUrl: '',
      isButtonDisabled: false
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.thumbnailFile = file;
        this.previewThumbnail(file);
      }
    },
    previewThumbnail(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.thumbnailUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async uploadThumbnail(file) {
      const storageRef = ref(storage, `thumbnails/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          null,
          (error) => {
            console.error("썸네일 업로드 중 오류가 발생했습니다: ", error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
    },
    async createBoard() {
      if (this.boardTitle.trim() === '') {
        alert("게시판 이름을 입력하세요.");
        return;
      }

      const user = auth.currentUser;

      if (!user) {
        alert("로그인이 필요합니다.");
        return;
      }

      // 버튼 비활성화
      this.isButtonDisabled = true;
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 10000);

      try {
        // 게시판 이름 중복 확인
        const duplicateCheckQuery = query(collection(db, "boards"), where("title", "==", this.boardTitle));
        const querySnapshot = await getDocs(duplicateCheckQuery);

        if (!querySnapshot.empty) {
          alert('같은 이름의 게시판이 이미 존재합니다.');
          return;
        }

        let thumbnailUrl = '';
        if (this.thumbnailFile) {
          thumbnailUrl = await this.uploadThumbnail(this.thumbnailFile);
        }

        const newBoard = {
          title: this.boardTitle,
          description: this.boardDescription,
          createdAt: new Date().toISOString(),
          creatorUid: user.uid,
          thumbnailUrl: thumbnailUrl
        };

        const docRef = await addDoc(collection(db, "boards"), newBoard);

        alert("게시판이 생성되었습니다.");
        this.$router.push(`/boards/${docRef.id}`);
      } catch (error) {
        console.error("게시판 생성 중 오류가 발생했습니다: ", error);
        this.isButtonDisabled = false;
      }
    }
  }
};
</script>

<style scoped>
.create-board {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.create-board h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.create-board input,
.create-board textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.file-upload {
  margin-bottom: 10px;
}

.thumbnail-preview {
  margin-bottom: 10px;
}

.thumbnail-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.create-board button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-board button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.create-board button:hover:not(:disabled) {
  background-color: #0056b3;
}
</style>


