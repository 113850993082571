<template>
  <div class="community">
    <h2 class="rainbow-text">자유게시판</h2>
    <div class="edit-button" v-if="isAuthenticated">
      <router-link to="/create-post">
        <button>+</button>
      </router-link>
    </div>
    <div class="search-bar">
      <input type="text" v-model="searchQuery" placeholder="검색어를 입력하세요" @input="filterPosts" />
    </div>
    <div class="board-list-button">
      <router-link to="/boards">
        <button>다른 게시판으로 이동</button>
      </router-link>
    </div>
    <div class="posts">
      <div v-for="post in sortedAndFilteredPosts" :key="post.id" class="post">
        <router-link :to="{ name: 'PostDetail', params: { postId: post.id } }">
          <div class="post-header">
            <img :src="post.user?.photoURL || 'default-profile-photo.jpg'" alt="Profile Photo" class="profile-photo">
            <span>{{ post.user.uniqueId || 'Unknown' }}</span>
            <div class="post-likes" :class="{ 'top-post-likes': isTopPost(post) }">
              <small>추천: {{ post.likes || 0 }}</small>
            </div>
          </div>
          <div class="post-body">
            <img v-if="post.thumbnailUrl" :src="post.thumbnailUrl" alt="Thumbnail Image" class="thumbnail-image" />
            <div class="post-info">
              <h4>{{ post.title }}</h4>
              <small>{{ formatDate(post.createdAt) }}</small>
              <div class="post-views">
                <small>조회수: {{ post.views || 0 }}</small>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

export default {
  name: 'Community',
  data() {
    return {
      posts: [],
      searchQuery: ''
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    sortedAndFilteredPosts() {
      return this.filteredPosts().sort((a, b) => this.calculatePostScore(b) - this.calculatePostScore(a));
    }
  },
  methods: {
    async fetchPosts() {
      const postsQuery = query(collection(db, "posts"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(postsQuery);
      this.posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      this.$emit('posts-fetched', this.posts);
    },
    formatDate(dateString) {
      const options = { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('ko-KR', options);
    },
    filteredPosts() {
      if (!this.searchQuery) {
        return this.posts;
      }
      const query = this.searchQuery.toLowerCase();
      return this.posts.filter(post => 
        post.title.toLowerCase().includes(query) ||
        (post.content && post.content.toLowerCase().includes(query))
      );
    },
    calculatePostScore(post) {
      const now = new Date();
      const postDate = new Date(post.createdAt);
      const timeDiffInHours = (now - postDate) / (1000 * 60 * 60); // 시간 단위 차이
      const daysPassed = timeDiffInHours / 24; // 일 단위로 변환
      const freshnessScore = Math.max(0, 5 - daysPassed); // 하루 지나면 1점씩 차감, 5일 후에는 0점
      const popularityScore = (post.likes || 0); // 좋아요 수만 반영한 점수
      return freshnessScore + popularityScore;
    },
    isTopPost(post) {
      return this.sortedAndFilteredPosts.slice(0, 5).some(topPost => topPost.id === post.id);
    }
  },
  async created() {
    await this.fetchPosts();
  }
};
</script>

<style scoped>
.rainbow-text {
  font-size: 2em;
  font-weight: bold;
  background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
  color: transparent;
  background-clip: text;
}


.community {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.edit-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.edit-button button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
}

.edit-button button:hover {
  background-color: #0056b3;
}


.search-bar {
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-bar input {
  width: 80%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 30px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.search-bar input::placeholder {
  color: #aaa;
}

.search-bar input:focus {
  border-color: #999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.board-list-button {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.board-list-button button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.board-list-button button:hover {
  background-color: #218838;
}

.posts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.post {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  padding: 15px;
}

.post:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.post-header .profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-header .post-likes {
  margin-left: auto;
}

.post-header .post-likes.top-post-likes small {
  color: red;
}

.post-header span {
  font-weight: bold;
}

.post-body {
  display: flex;
  flex-direction: column;
}

.thumbnail-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.post-info h4 {
  margin: 0;
  font-size: 1.2em;
}

.post-info small {
  color: #999;
}

.post-views small {
  color: #999;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
































