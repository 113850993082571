// src/i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    message: {
      loading: 'Loading...',
      loginFailed: 'Login failed. Please try again.',
      welcome: 'Welcome, {name}!',
      invalidUniqueId: 'Unique ID must include alphanumeric characters and special characters within 10 characters.',
      duplicateID: 'This ID is already in use.',
      invalidUserName: 'Username must be within 8 characters.',
      like: 'Like',
      dislike: 'Dislike',
      confirm: 'Confirm',
      home: 'Home',
      userNamePlaceholder: 'User Name',
      uniqueIdPlaceholder: 'Unique ID',
      likes: 'Likes',
      dislikes: 'Dislikes',
      search: 'Enter unique ID...',
      searchButton: 'Search',
      profilePicture: 'User Profile',
      startDate: 'Start Date',
      endDate: 'End Date',
      selectDate: 'Select Date',
      fetchComments: 'Fetch Comments',
    }
  },
  ko: {
    message: {
      loading: '로딩 중...',
      loginFailed: '로그인 실패. 다시 시도해 주세요.',
      welcome: '환영합니다, {name}님!',
      invalidUniqueId: '고유 ID는 영문자, 숫자, 특수문자를 포함하여 10글자 이하로',
      duplicateID: '이미 사용 중인 ID입니다.',
      invalidUserName: '사용자 이름은 8글자 이하로',
      like: '좋아요',
      dislike: '싫어요',
      confirm: '확인',
      home: '홈으로',
      userNamePlaceholder: '사용자 이름',
      uniqueIdPlaceholder: '고유 ID',
      likes: '좋아요',
      dislikes: '싫어요',
      search: '고유 ID를 입력하세요...',
      searchButton: '검색',
      profilePicture: '사용자 프로필',
      startDate: '시작 날짜',
     endDate: '종료 날짜',
     selectDate: '날짜 선택',
     fetchComments: '댓글 조회',
    }
  }
};

const i18n = createI18n({
  legacy: false, // Ensure legacy mode is off for Vue 3
  locale: navigator.language.includes('ko') ? 'ko' : 'en', // 브라우저 언어 설정에 따라 기본 언어 결정
  fallbackLocale: 'en', // 지원하지 않는 언어의 경우 영어로 대체
  messages,
});

export default i18n;
