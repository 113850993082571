<template>
  <div class="post-header" @click="handleProfileClick">
    <img :src="userProfile.photoURL" alt="Profile Photo" class="profile-photo">
    <span>{{ userProfile.uniqueId || userProfile.uid }}</span>
  </div>
</template>

<script>
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    defaultProfilePhoto: {
      type: String,
      default: '/default-profile-photo.jpg'
    }
  },
  data() {
    return {
      userProfile: {
        uniqueId: '',
        uid: '',
        photoURL: this.defaultProfilePhoto,
        displayName: '',
      }
    };
  },
  async mounted() {
    console.log('UserProfileHeader mounted with user:', this.user);
    this.userProfile.uniqueId = this.user.uniqueId || ''; // Ensure uniqueId is set
    this.userProfile.photoURL = this.user.photoURL || this.defaultProfilePhoto;
    await this.fetchUserProfile();
  },
  methods: {
    async fetchUserProfile() {
      const userId = this.user.uid || this.user.uniqueId;
      if (!userId) {
        console.error("User UID is missing or invalid");
        return;
      }

      try {
        console.log('Fetching profile for user UID:', userId);
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          this.userProfile = {
            uniqueId: userData.uniqueId || userId,
            uid: userId,
            photoURL: userData.photoURL || this.defaultProfilePhoto,
            displayName: userData.displayName || '',
          };
          console.log('Fetched user profile data:', this.userProfile);
        } else {
          console.error('No such user document!');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    },
    handleProfileClick() {
      const uniqueId = this.userProfile.uniqueId || this.userProfile.uid;
      console.log('Navigating to user profile with uniqueId:', uniqueId);

      if (uniqueId && uniqueId !== 'anonymous') {
        this.$router.push({ name: 'UserProfile', params: { uniqueId } }).catch(err => {
          console.error('Router push error:', err);
          // Fallback to window.location if router push fails
          window.location.href = `/user/${uniqueId}`;
        });
      } else {
        console.error('uniqueId is missing or invalid');
      }
    }
  }
};
</script>
<style scoped>
.post-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

span {
  font-size: 1em;
}
</style>









