<template>
  <div class="bio">
    <div v-if="isLoading">로딩 중...</div>
    <div v-else>
      <button v-if="isOwner && !isEditing" @click="editBio" class="edit-button">✐</button>
      <div v-if="isEditing">
        <textarea v-model="bio" placeholder="자기소개를 입력하세요 (500자 이하)" maxlength="500"></textarea>
        <div class="buttons">
          <button @click="saveBio" :disabled="isSaving">저장</button>
          <button @click="cancelEdit">취소</button>
        </div>
      </div>
      <p v-else>{{ savedBio || '자기소개가 없습니다.' }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { doc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '@/firebase'; // Firestore 인스턴스 가져오기
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const auth = getAuth();
    const bio = ref('');
    const savedBio = ref('');
    const isSaving = ref(false);
    const isEditing = ref(false);
    const isOwner = ref(false);
    const isLoading = ref(true);

    const fetchBio = (userId) => {
      const userBioRef = doc(db, 'user_bios', userId);

      // 실시간 업데이트를 위한 onSnapshot 사용
      const unsubscribe = onSnapshot(userBioRef, (doc) => {
        if (doc.exists()) {
          savedBio.value = doc.data().bio || '';
          if (!isEditing.value) {
            bio.value = savedBio.value;
          }
        } else {
          // 문서가 존재하지 않으면 빈 bio로 문서를 생성합니다.
          setDoc(userBioRef, { bio: '' });
        }
        isLoading.value = false; // 데이터가 로드되면 로딩 상태 해제
      }, (error) => {
        console.error('Error fetching bio:', error);
        isLoading.value = false; // 오류가 발생해도 로딩 상태 해제
      });

      return unsubscribe; // 컴포넌트 언마운트 시에 스냅샷 리스너를 해제하기 위해 반환
    };

    let unsubscribe = null;

    onMounted(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          isOwner.value = user.uid === props.userId;
        } else {
          isOwner.value = false;
        }

        // 초기 데이터 로드
        if (unsubscribe) unsubscribe();
        unsubscribe = fetchBio(props.userId);
      });
    });

    watch(() => props.userId, (newUserId) => {
      if (unsubscribe) {
        unsubscribe(); // 이전 스냅샷 리스너 해제
      }
      isLoading.value = true;
      unsubscribe = fetchBio(newUserId); // 새로운 userId로 데이터 로드
      onAuthStateChanged(auth, (user) => {
        if (user) {
          isOwner.value = user.uid === newUserId;
        } else {
          isOwner.value = false;
        }
      });
    });

    const editBio = () => {
      isEditing.value = true;
    };

    const cancelEdit = () => {
      bio.value = savedBio.value;
      isEditing.value = false;
    };

    const saveBio = async () => {
      isSaving.value = true;
      try {
        const userBioRef = doc(db, 'user_bios', props.userId);
        await updateDoc(userBioRef, { bio: bio.value });
        isEditing.value = false;
        savedBio.value = bio.value;
        alert('자기소개가 저장되었습니다.');
      } catch (error) {
        console.error('Error saving bio:', error);
        alert('자기소개를 저장하는데 실패했습니다.');
      } finally {
        isSaving.value = false;
      }
    };

    return {
      bio,
      savedBio,
      isSaving,
      isEditing,
      isOwner,
      isLoading,
      editBio,
      cancelEdit,
      saveBio,
    };
  },
};
</script>

<style scoped>
.bio {
  margin: 20px;
}
.edit-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
.buttons {
  display: flex;
  gap: 10px;
}
</style>



































