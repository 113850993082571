<template>
  <div id="app">
    <div class="box" v-if="$route.path === '/'">
      <slot><img src="android-icon-192x192.png" alt="이미지 설명" class="custom-image"></slot>
    </div>
    <div class="logo-container" style="position: absolute; top: 0; left: 0; padding: 10px;">
      <!-- 로고 영역 -->
    </div>
    <Search v-if="$route.path === '/'" />
    <Box v-if="$route.path === '/'" :posts="posts" />
    <MainWordExtractor v-if="$route.path === '/'" />
    <GoogleLogin v-if="!isAuthenticated && $route.path === '/'" />
    <Profile v-if="isAuthenticated && $route.path === '/'" />
    
    <Gi v-if="$route.path === '/'" />
    <router-view @posts-fetched="setPosts" />
    <button v-if="$route.path === '/'" @click="goToCommunity" class="community-button">🌐</button>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Search from './components/Search.vue';
import GoogleLogin from './components/GoogleLogin.vue';
import Profile from './components/Profile.vue';
import Gi from './components/Gi.vue';
import Box from './components/Box.vue';
import MainWordExtractor from './components/MainWordExtractor.vue';
import { db } from "./firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

export default {
  name: 'App',
  components: {
    Search,
    GoogleLogin,
    Profile,
    Gi,
    Box,
    MainWordExtractor,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const posts = ref([]);

    const setPosts = (fetchedPosts) => {
      posts.value = fetchedPosts;
    };

    const fetchPosts = async () => {
      const postsQuery = query(collection(db, "posts"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(postsQuery);
      setPosts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    onMounted(() => {
      if (router.currentRoute.value.path === '/') {
        fetchPosts();
      }
    });

    const goToCommunity = () => {
      router.push('/community');
    };

    return {
      isAuthenticated,
      posts,
      goToCommunity,
      setPosts,
      fetchPosts,
    };
  },
};
</script>





<style>
/* Global styles */
.box {
  display: none; /* 기본적으로는 숨김 */
}
.custom-image {
  width: 50px;  /* 원하는 너비로 수정 */
  height: 50px; /* 원하는 높이로 수정 */
  border-radius: 10%;  /* 모서리를 둥글게 */
  margin-left: 10px;  /* 원하는 만큼 오른쪽으로 이동 */
}

.logo-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
}

.community-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 18px;
}

.community-button:hover {
  background-color: #0056b3;
}
</style>














