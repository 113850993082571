import { createStore } from 'vuex';
import { db, auth } from './firebase';
import {
  doc,
  getDoc,
  setDoc,
  query,
  where,
  collection,
  getDocs,
  runTransaction,
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import createPersistedState from 'vuex-persistedstate';

async function fetchUserReactions(userId) {
  if (!userId) return;

  try {
    const userReactionsRef = doc(db, "userReactions", userId);
    const userReactionsDoc = await getDoc(userReactionsRef);
    if (!userReactionsDoc.exists()) return;

    return userReactionsDoc.data();
  } catch (error) {
    console.error("Error fetching user reactions: ", error);
  }
}

const dataState = createPersistedState({
  paths: ['userProfile', 'isAuthenticated', 'user', 'reactionsCount']
});

const store = createStore({
  plugins: [dataState],
  
  state: {
    likedUsers: [],
    topLikedUsers: [],
    recommendedUsers: [],
    comments: [],
    userProfile: {
      uid: '',
      displayName: '',
      photoURL: '',
      uniqueId: '',
    },
    isAuthenticated: false,
    isOwner: false,
    user: null,
    pageUniqueId: '',
    uniqueId: '',
    reactionsCount: {
      likes: 0,
      dislikes: 0,
    },
  },
  mutations: {
    SET_IS_OWNER(state, isOwner) {
      state.isOwner = isOwner;
    },
    SET_ALL_USERS(state, users) {
      state.allUsers = users;
    },
    SET_LIKED_ITEMS(state, likedItems) {
      state.likedItems = likedItems;
    },
    SET_LIKED_ITEMS_INFO(state, likedItemsInfo) {
      state.likedItemsInfo = likedItemsInfo;
    },
    RESET_STATE(state) {
      state.comments = [];
    },
    SET_TOP_LIKED_USERS(state, users) {
      state.recommendedUsers = users;
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    SET_LIKED_USERS(state, likedUsers) {
      state.likedUsers = likedUsers;
    },
    SET_USER(state, user) {
      state.user = user;
      state.isAuthenticated = !!user;
    },
    SET_PROFILE(state, profile) {
      state.userProfile = profile;
    },
    SET_PROFILE_PICTURE(state, { uid, photoURL }) {
      if (state.userProfile.uid === uid) {
        state.userProfile.photoURL = photoURL;
      }
    },
    SET_REACTIONS_COUNT(state, { likes, dislikes }) {
      state.reactionsCount.likes = likes;
      state.reactionsCount.dislikes = dislikes;
    },
    SET_RECOMMENDED_USERS(state, users) {
      state.recommendedUsers = users;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    
    async fetchLikedUsers({ commit, state }) {
      const userId = state.user ? state.user.uid : '';
      if (!userId) return;

      try {
        const userLikesRef = doc(db, "userReactions", userId);
        const userLikesDoc = await getDoc(userLikesRef);
        if (!userLikesDoc.exists()) {
          commit('SET_LIKED_USERS', []);
          return;
        }

        const likesData = userLikesDoc.data();
        const likedUserUniqueIds = Object.keys(likesData).filter(key => likesData[key] === 'like');

        if (likedUserUniqueIds.length === 0) {
          commit('SET_LIKED_USERS', []);
          return;
        }

        const BATCH_SIZE = 30; // Increase batch size to reduce the number of queries
        const batches = [];
        for (let i = 0; i < likedUserUniqueIds.length; i += BATCH_SIZE) {
          const batch = likedUserUniqueIds.slice(i, i + BATCH_SIZE);
          const usersRef = collection(db, "users");
          const q = query(usersRef, where("uniqueId", "in", batch));
          batches.push(getDocs(q));
        }

        const results = await Promise.all(batches);
        const usersData = results.flatMap(result => result.docs.map(doc => ({
          uid: doc.id,
          ...doc.data()
        })));

        const usersWithReactions = await Promise.all(usersData.map(async user => {
          try {
            const userReactions = await fetchUserReactions(user.uid);
            return { ...user, userReactions };
          } catch (error) {
            console.error("Error fetching user reactions: ", error);
            return null;
          }
        }));

        commit('SET_LIKED_USERS', usersWithReactions.filter(user => user !== null));
      } catch (error) {
        console.error("Error fetching liked users: ", error);
      }
    },

    async fetchAllUsers({ commit }) {
      try {
        const usersRef = collection(db, "users");
        const userDocs = await getDocs(usersRef);
        const allUsers = userDocs.docs.map(doc => ({
          uniqueId: doc.id,
          ...doc.data()
        }));
        commit('SET_ALL_USERS', allUsers);
      } catch (error) {
        console.error("모든 사용자 정보를 불러오는 과정에서 오류가 발생했습니다:", error);
      }
    },   

    async fetchReactionsCount({ commit }, uniqueId) {
      const reactionsRef = collection(db, "userReactions");

      try {
        const qLike = query(reactionsRef, where(uniqueId, "==", "like"));
        const likeSnapshot = await getDocs(qLike);
        const likes = likeSnapshot.size;

        const qDislike = query(reactionsRef, where(uniqueId, "==", "dislike"));
        const dislikeSnapshot = await getDocs(qDislike);
        const dislikes = dislikeSnapshot.size;

        commit('SET_REACTIONS_COUNT', {
          likes,
          dislikes
        });
      } catch (error) {
        console.error("Error fetching reactions count: ", error);
      }
    },

    async toggleReaction({ commit, state }, { uniqueId, reaction }) {
      if (!state.user) return;
      
      const userId = state.user.uid;
      const userReactionsRef = doc(db, "userReactions", userId);

      try {
        await runTransaction(db, async (transaction) => {
          const userReactionsDoc = await transaction.get(userReactionsRef);
          let userReactions = userReactionsDoc.exists() ? userReactionsDoc.data() : {};

          if (userReactions[uniqueId]) {
            if (userReactions[uniqueId] === reaction) {
              delete userReactions[uniqueId];
            } else {
              userReactions[uniqueId] = reaction;
            }
          } else {
            userReactions[uniqueId] = reaction;
          }

          transaction.set(userReactionsRef, userReactions, { merge: true });
        });
      } catch (error) {
        console.error("Failed to toggle reaction: ", error);
      }
    },
    
    async fetchUserReaction({ state }, { uniqueId }) {
      if (!state.user) return '';
      
      const userId = state.user.uid;
      const userReactionsRef = doc(db, "userReactions", userId);
      const docSnap = await getDoc(userReactionsRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        return data[uniqueId] || '';
      }
      return '';
    },

    async login({ commit }, user) {
      commit('SET_USER', user);
      commit('SET_IS_AUTHENTICATED', true);
    },

    async logout({ commit }) {
      commit('SET_USER', null);
      commit('SET_IS_AUTHENTICATED', false);
    },

    async saveUserProfile({ commit }, { uid, displayName, photoURL, uniqueId }) {
      await setDoc(doc(db, "users", uid), { displayName, photoURL, uniqueId }, { merge: true });
      const userProfile = { uid, displayName, photoURL, uniqueId };
      commit('SET_PROFILE', userProfile);
    },

    async fetchUserProfile({ commit }, uniqueId) {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("uniqueId", "==", uniqueId));
      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          const uid = querySnapshot.docs[0].id;
          const userProfile = { uid, ...userData };
          commit('SET_PROFILE', userProfile);
        } else {
          console.error("No such document with uniqueId:", uniqueId);
        }
      } catch (error) {
        console.error("Error fetching user profile with uniqueId:", uniqueId, error);
      }
    },

    async saveUserBio({ state }, bio) {
      if (!state.userProfile.uid) return;
      const userBioRef = doc(db, 'user_bios', state.userProfile.uid);
      try {
        await setDoc(userBioRef, { bio }, { merge: true });
      } catch (error) {
        console.error("Error saving user bio:", error);
      }
    },

    setOwnerStatus({ commit }, isOwner) {
      commit('SET_IS_OWNER', isOwner);
    },

    async initializeAuthState({ commit }) {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
    
            if (!userDocSnap.exists()) {
              await setDoc(userDocRef, {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                photoURL: '',
                uniqueId: user.uid
              });
            }
            const userProfile = { uid: user.uid, displayName: user.displayName, photoURL: '', uniqueId: user.uid };
            commit('SET_USER', user);
            commit('SET_PROFILE', userProfile);
          } catch (error) {
            console.error('Error fetching or setting user profile:', error);
            commit('SET_USER', null);
            commit('SET_PROFILE', {});
          }
        } else {
          commit('SET_USER', null);
          commit('SET_PROFILE', {});
        }
      });
    }
  },    
  
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    reactionsCount: (state) => state.reactionsCount,
    recommendedUsers: (state) => state.recommendedUsers,
    userProfile: (state) => state.userProfile,
    getRecommendations: (state) => state.recommendations,
    isOwner: (state) => state.isOwner,
  }
});

export default store;




















