<template>
  <div class="post-form">
    <div v-if="renderComponent">
      <div class="post-header">
        <img :src="userProfile.photoURL || 'default-profile-photo.jpg'" alt="Profile Photo" class="profile-photo">
        <span>{{ userProfile.uniqueId || userProfile.displayName }}</span>
      </div>
      <input v-model="newPostTitle" placeholder="Write a title..." />
      <div class="content-editor">
        <div 
          contenteditable="true" 
          class="editable-content" 
          @input="updateContent" 
          ref="contentEditor"
          @paste="handlePaste"
          @mousedown="handleMouseDown"
        ></div>
      </div>
      <div class="file-upload">
        <label>Upload an image:</label>
        <input type="file" @change="handleFileUpload('postImageFile', $event)" />
      </div>
      <div class="file-upload">
        <label>Upload a thumbnail:</label>
        <input type="file" @change="handleFileUpload('thumbnailImageFile', $event)" />
      </div>
      <div v-if="thumbnailImageFile" class="thumbnail-preview">
        <img :src="thumbnailImageFile" alt="Thumbnail Preview" class="thumbnail-preview-image" />
      </div>
      <div class="file-upload">
        <label>Upload a video:</label>
        <input type="file" @change="handleFileUpload('videoFile', $event)" />
      </div>
      <div class="link-upload">
        <label>Attach a link:</label>
        <input v-model="newPostLink" @input="fetchLinkPreview" placeholder="Paste a link..." />
      </div>
      <div v-if="linkPreview" class="link-preview">
        <a :href="newPostLink" target="_blank">
          <img :src="linkPreview.image" alt="Link Preview Image" class="link-preview-image" />
          <p>{{ linkPreview.title }}</p>
        </a>
      </div>
      <button @click="submitPost">Submit</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import { auth, db, storage } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

export default {
  name: 'PostForm',
  data() {
    return {
      newPostTitle: '',
      postContent: '',
      newPostLink: '',
      linkPreview: null,
      postImageFile: null,
      thumbnailImageFile: null,
      videoFile: null,
      resizingImage: null,
      startX: 0,
      startWidth: 0,
      renderComponent: false,
      userProfile: {
        photoURL: '',
        displayName: '',
        uniqueId: ''
      }
    };
  },
  computed: {
    ...mapGetters(['userProfile'])
  },
  methods: {
    ...mapActions(['initializeAuthState']),
    async refreshUserProfile() {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            this.userProfile.photoURL = userData.photoURL || 'default-profile-photo.jpg';
            this.userProfile.displayName = userData.displayName || 'Anonymous';
            this.userProfile.uniqueId = userData.uniqueId || user.uid;
          } else {
            this.userProfile.photoURL = 'default-profile-photo.jpg';
            this.userProfile.displayName = 'Anonymous';
            this.userProfile.uniqueId = user.uid;
          }
        } else {
          this.userProfile.photoURL = 'default-profile-photo.jpg';
          this.userProfile.displayName = 'Anonymous';
          this.userProfile.uniqueId = '';
        }
        this.renderComponent = true;
      });
    },
    updateContent() {
      const contentEditor = this.$refs.contentEditor;
      this.postContent = contentEditor.innerHTML.replace(/<div><img src="(.*?)" class="inserted-image"><\/div>/g, '[img]$1[/img]').replace(/<div><video src="(.*?)" class="inserted-video" controls><\/video><\/div>/g, '[video]$1[/video]');
    },
    async handleFileUpload(type, event) {
      const file = event.target.files[0];
      if (file) {
        const fileUrl = await this.uploadFile(file);
        if (fileUrl) {
          const contentEditor = this.$refs.contentEditor;
          contentEditor.focus();
          if (type === 'postImageFile') {
            const imgTag = `<div><img src="${fileUrl}" class="inserted-image" style="max-width: 50%;" /></div>`;
            contentEditor.innerHTML += imgTag;
            this.updateContent();
          } else if (type === 'thumbnailImageFile') {
            this.thumbnailImageFile = fileUrl;
          } else if (type === 'videoFile') {
            const videoTag = `<div><video src="${fileUrl}" class="inserted-video" controls style="max-width: 100%;"></video></div>`;
            contentEditor.innerHTML += videoTag;
            this.updateContent();
          }
        }
      }
    },
    handlePaste(event) {
      event.preventDefault();
      const text = (event.clipboardData || window.clipboardData).getData('text');
      document.execCommand('insertHTML', false, text);
    },
    async uploadFile(file) {
      if (!file) return '';
      const storageReference = storageRef(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageReference, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          (error) => {
            console.error('File upload failed:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          }
        );
      });
    },
    async fetchLinkPreview() {
      if (this.newPostLink) {
        try {
          const response = await fetch(`https://api.linkpreview.net?key=YOUR_API_KEY&q=${this.newPostLink}`);
          const data = await response.json();
          this.linkPreview = data;
        } catch (error) {
          console.error('Failed to fetch link preview:', error);
        }
      } else {
        this.linkPreview = null;
      }
    },
    async submitPost() {
      if (!this.newPostTitle.trim() || !this.postContent.trim()) {
        alert("제목과 내용을 입력하세요.");
        return;
      }

      try {
        const newPost = {
          user: {
            displayName: this.userProfile.displayName,
            photoURL: this.userProfile.photoURL,
            uniqueId: this.userProfile.uniqueId
          },
          title: this.newPostTitle,
          content: this.postContent,
          thumbnailUrl: this.thumbnailImageFile,
          link: this.newPostLink,
          linkPreview: this.linkPreview,
          createdAt: new Date().toISOString(),
          views: 0
        };

        await addDoc(collection(db, "posts"), newPost);

        this.$router.push('/community');
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    },
    handleMouseDown(event) {
      const target = event.target;
      if (target.tagName === 'IMG' && target.classList.contains('inserted-image')) {
        this.resizingImage = target;
        this.startX = event.clientX;
        this.startWidth = target.offsetWidth;
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
      }
    },
    handleMouseMove(event) {
      if (this.resizingImage) {
        const newWidth = this.startWidth + (event.clientX - this.startX);
        this.resizingImage.style.width = `${newWidth}px`;
      }
    },
    handleMouseUp() {
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);
      this.resizingImage = null;
    }
  },
  created() {
    this.refreshUserProfile();
  }
};
</script>









<style scoped>
.post-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.content-editor {
  width: 100%;
  margin-bottom: 10px;
}

.content-editor .editable-content {
  width: 100%;
  min-height: 400px; /* 최소 높이 설정 */
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  outline: none; /* 클릭시 테두리 제거 */
}

.file-upload,
.link-upload {
  margin-bottom: 10px;
}

.post-form button {
  align-self: flex-end;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.image-preview img,
.inserted-image {
  max-width: 50%; /* 기본 이미지 크기를 기존의 절반으로 조정 */
  display: block;
  margin: 10px 0; /* 이미지와 텍스트 사이 간격 설정 */
  cursor: nwse-resize; /* 크기 조정 커서 설정 */
}

.link-preview {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.link-preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 5px;
}

.link-preview p {
  margin: 0;
}
</style>





















