import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // 기존의 store.js
import i18n from './i18n'; // i18n 설정 import
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


import { library } from '@fortawesome/fontawesome-svg-core';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faThumbsUp, faThumbsDown);

// Firebase 설정 정보
const firebaseConfig = {
  apiKey: "AIzaSyD-UdrbTRjye0OrreENepa_d7RS6rcVaF8",
  authDomain: "tupo-a2cba.firebaseapp.com",
  databaseURL: "https://tupo-a2cba-default-rtdb.firebaseio.com",
  projectId: "tupo-a2cba",
  storageBucket: "tupo-a2cba.appspot.com",
  messagingSenderId: "770828873026",
  appId: "1:770828873026:web:ce5b8d608bbb394fdf2cdb",
  measurementId: "G-XQYZMVCRPE"
};

// Firebase 초기화
initializeApp(firebaseConfig);
// Firebase Authentication 객체 가져오기
const auth = getAuth();

// 사용자 인증 상태 변경 감지
onAuthStateChanged(auth, user => {
  if (user) {
    // 사용자가 로그인한 경우
    store.dispatch('login', {
      name: user.displayName,
      email: user.email,
      uid: user.uid,
      photoURL: user.photoURL,
    });
  } else {
    // 사용자가 로그아웃한 경우
    store.dispatch('logout');
  }
});

// 커뮤니티 스토어 모듈을 등록합니다.


// 앱 마운트 전에 스토어의 인증 상태 초기화
store.dispatch('initializeAuthState').then(() => {
  // Vue 애플리케이션 인스턴스 생성
  const app = createApp(App);
  app.use(router); // Vue Router 사용
  app.use(store);  // Vuex 스토어 사용
  app.use(i18n); // i18n 사용 설정
  app.component('font-awesome-icon', FontAwesomeIcon);

  // Vue 애플리케이션 마운트
  app.mount('#app');
}).catch(error => {
  console.error("스토어 초기화 중 오류 발생:", error);
});










